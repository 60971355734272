import React, { useState, useEffect } from 'react';
import axios from 'axios';

// CONTEXT STATE
export const ProductDataContextState = React.createContext({
  //USER
  userId: 0,
  products: [],
  allProducts: () => {},
  updateUserId: () => {},
  getDesiredSkuInfo: () => {},
  allDepartments: [],
  allCategories: [],
  allManufacturers: [],
  allTypes: [],
});

// PRIMARY COMPONENT
const ProductDataContext = ({ children }) => {
  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [userIdState, setUserIdState] = useState(54156);
  const [getAllProductsState, setAllProductsState] = useState([]);
  const [allDepartmentsState, setAllDepartmentsState] = useState([]);
  const [allCategoriesState, setAllCategoriesState] = useState([]);
  const [allManufacturersState, setAllManufacturersState] = useState([]);
  const [allTypesState, setAllTypesState] = useState([]);

  // FUNCTIONS
  const updateUserId = (value) => setUserIdState(value);

  const allProducts = async (value) => {
    // Fetch all data in parallel
    const [productData, departmentData, categoryData, manufacturerData, typeData] =
      await Promise.all([
        axios.get(`/products/all/${value}`),
        axios.get(`/products/allDepartments`),
        axios.get(`/products/allCategories`),
        axios.get(`/products/allManufacturers`),
        axios.get(`/products/allTypes`),
      ]);

    const products = productData.data;
    const departments = departmentData.data;
    const categories = categoryData.data;
    const manufacturers = manufacturerData.data;
    const types = typeData.data;

    setAllDepartmentsState(departments);
    setAllCategoriesState(categories);
    setAllManufacturersState(manufacturers);
    setAllTypesState(types);

    // Create a new array by mapping over the products
    const combinedData = products.map((product) => {
      const department = departments.find(
        (dept) => dept.product_department_id === product.product_department_id
      );
      const category = categories.find(
        (cat) => cat.product_category_id === product.product_category_id
      );
      const manufacturer = manufacturers.find(
        (mfr) => mfr.product_manufacturer_id === product.product_manufacturer_id
      );

      const type = types.find((type) => type.product_type_id === product.product_type_id);

      return {
        ...product,
        department: department ? department.description : 'Unknown',
        category: category ? category.description : 'Unknown',
        manufacturer: manufacturer ? manufacturer.description : 'Unknown',
        type: type ? type.description : 'Unknown',
      };
    });

    setAllProductsState(combinedData);
    return null;
  };

  const getAllDepartments = async () => {
    try {
      const response = await axios.get(`/products/allDepartments`);
      console.log('departments: ', response.data);
      setAllDepartmentsState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAllCategories = async () => {
    try {
      const response = await axios.get(`/products/allCategories`);
      console.log('categories: ', response.data);
      setAllCategoriesState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const getAllManufacturers = async () => {
    try {
      const response = await axios.get(`/products/allManufacturers`);
      setAllManufacturersState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        await Promise.all([allProducts()]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        if (isMounted) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  // FUNCTIONS
  const getDesiredSkuInfo = (type, value) => {
    const valueString = value ? value.toString().toLowerCase() : 0;
    const valueNumber = parseInt(value);

    console.log('valueString: ' + valueString + ' valueNumber: ' + valueNumber);
    let results = [];
    switch (type) {
      case 'desc':
        results = getAllProductsState.filter((e) =>
          e.description.toLowerCase().includes(valueString)
        );
        break;
      case 'deptid':
        results = getAllProductsState.filter((e) => e.product_department_id === valueNumber);
        break;
      case 'category':
        results = getAllProductsState.filter((e) => e.product_category_id === valueNumber);
        break;
      case 'mfrid':
        results = getAllProductsState.filter((e) => e.product_manufacturer_id === valueNumber);
        break;
      case 'id':
        results = getAllProductsState.filter((e) => e.product_id === valueNumber);
        break;
      case 'code':
        results = getAllProductsState.filter((e) => e.product_code === valueString);
        break;
      case 'fuzzy':
        results = getAllProductsState.filter(
          (e) =>
            e.product_id === valueNumber ||
            e.product_code === valueString ||
            (e.upc_code?.includes(valueString) && valueString.length > 9) ||
            e.description?.toLowerCase().includes(valueString)
        );
        break;
      case 'nocategory':
        results = getAllProductsState.filter((e) => e.product_category_id === null);
        break;
      case 'worn':
        results = getAllProductsState.filter(
          (e) => e.product_manufacturer_id === 250 || e.product_manufacturer_id === 555
        );
        break;
      case 'notv':
        results = getAllProductsState.filter(
          (e) => e.truevalue_id === null && [1, 2].includes(e.product_type_id)
        );
        break;
      case 'nocoo':
        results = getAllProductsState.filter((e) => e.country_of_origin === null);
        break;

      default:
        results = [];
    }
    return results;
  };

  const getCategory26 = () => {
    const result = getAllProductsState.filter((e) => e.product_category_id === 26);
    return result;
  };

  const getRuger = () => {
    const result = getAllProductsState.filter((e) => e.product_manufacturer_id === 369);
    return result;
  };

  const getByItemType = (id) => {
    const result = getAllProductsState.filter((e) => e.product_type_id === id);
    return result;
  };

  // CONTEXT OBJECT TO PASS THROUGH PROVIDER
  const contextValue = {
    userId: userIdState,
    products: getAllProductsState,
    allProducts,
    updateUserId,
    getCategory26,
    getRuger,
    getByItemType,
    getDesiredSkuInfo,
    allDepartments: allDepartmentsState,
    allCategories: allCategoriesState,
    allManufacturers: allManufacturersState,
    getAllDepartments,
    getAllCategories,
    getAllManufacturers,
    allTypes: allTypesState,
  };

  if (loading) {
    return <div>...Context Loading</div>;
  }

  return (
    <ProductDataContextState.Provider value={contextValue}>
      {children}
    </ProductDataContextState.Provider>
  );
};

export default ProductDataContext;
