import ReactDOMServer from 'react-dom/server';
import { AttachMoney, Paid, PriorityHigh, QuestionMark, Save } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import React, { useState } from 'react';
import dayjs from 'dayjs';

import {
  savePubliationContract,
  sendContractCreatedEmail,
} from '../../../../_GlobalFunctions/publicationFunctions';
import SnackBarV3 from '../../../../_GlobalComponents/SnackBarV3';

const CallToAction = ({ handleOpeningInfo, contractData }) => {
  // LOCAL STATE
  const [message, setMessage] = useState('');
  const [forceOpen, setForceOpen] = useState(0);

  const myObj = {
    ...contractData,
    notes: '',
    ss_publication_contract_status_id: 1,
    last_status_change: `${dayjs().format('YYYY-MM-DD HH:mm:ss')}`,
  };

  const handleSubmit = async () => {
    console.log('handleSubmit called');
    try {
      const response = await savePubliationContract(myObj);
      console.log('responsee: ', response);

      const content = (
        <div>
          <h3>Thank you for your participation!</h3>
          <h4>Contract Id #{response.insertId} Submitted...</h4>
        </div>
      );

      const emailObj = {
        emailAddress:
          'tony.ocon@oconfamily.net, tony.ocon@sportssouth.biz, athena.walls@sportssouth.biz',
        subject: `Marketing Contract #${response.insertId}`,
        htmlContent: ReactDOMServer.renderToStaticMarkup(content),
      };

      const res = await sendContractCreatedEmail(emailObj);
      setMessage('Great! Contract Saved...');
      setForceOpen(dayjs().unix());
      console.log('res: ', res);
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Button
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        onClick={() => handleOpeningInfo(true)}
      >
        <PriorityHigh />
        <span style={{ fontSize: '.8em' }}>Info</span>
      </Button>
      {/* <Divider orientation='vertical' flexItem />
      <Button sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Save />
        <span style={{ fontSize: '.8em' }}>Save for Later</span>
      </Button>
      <Divider orientation='vertical' flexItem /> */}

      <Button
        disabled={
          contractData.ss_publication_product_id > 0 &&
          contractData.entity_id > 0 &&
          contractData.product_manufacturers !== ''
            ? false
            : true
        }
        variant='contained'
        color='success'
        sx={{}}
        onClick={() => handleSubmit()}
        startIcon={<AttachMoney />}
      >
        <span style={{ fontSize: '.8em' }}>Submit</span>
      </Button>
      <SnackBarV3 message={message} forceOpen={forceOpen} />
    </>
  );
};

export default CallToAction;
