import React, { useEffect, useState } from 'react';
import axios from 'axios';
const HornadyPim = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [category, setCategory] = useState([]);
  const [calibers, setCalibers] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await axios.post(`/sandbox/hornady-pim`);
        setData(response.data);
        setCategory([...new Set(response.data.map((item) => String(item.ParentCategory).trim()))]);
        setCalibers([
          ...new Set(
            response.data.filter((p) => p.ParentCategory === 'Bullets').map((e) => e.Caliber)
          ),
        ]);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetch();
  }, []);

  if (loading) {
    return <div>... loading</div>;
  }
  return (
    <div>
      HornadyPim
      {data
        .filter((a) => a.ParentCategory === 'Ammunition')
        .map(
          (e, i) =>
            i < 100 && (
              <div key={i} style={{ width: '700px', display: 'flex' }}>
                <div style={{ width: '400px', display: 'flex', gap: 2, flexWrap: 'wrap' }}>
                  {e.ProductImage && (
                    <div style={{ aspectRatio: '1/1', width: '45px' }}>
                      <img
                        src={e.ProductImage}
                        alt='a'
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                  {e.ProductImage1 && (
                    <div style={{ aspectRatio: '1/1', width: '45px' }}>
                      <img
                        src={e.ProductImage1}
                        alt='a'
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                  {e.ProductImage2 && (
                    <div style={{ aspectRatio: '1/1', width: '45px' }}>
                      <img
                        src={e.ProductImage2}
                        alt='a'
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                  {e.ProductImage3 && (
                    <div style={{ aspectRatio: '1/1', width: '45px' }}>
                      <img
                        src={e.ProductImage3}
                        alt='a'
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                  {e.ProductImage4 && (
                    <div style={{ aspectRatio: '1/1', width: '45px' }}>
                      <img
                        src={e.ProductImage4}
                        alt='a'
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                  {e.ProductImage5 && (
                    <div style={{ aspectRatio: '1/1', width: '45px' }}>
                      <img
                        src={e.ProductImage5}
                        alt='a'
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                  {e.ProductImage6 && (
                    <div style={{ aspectRatio: '1/1', width: '45px' }}>
                      <img
                        src={e.ProductImage6}
                        alt='a'
                        style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                </div>
                <span style={{ display: 'inline-block', flex: 1 }}>{e.ProductName}</span>
              </div>
            )
        )}
    </div>
  );
};

export default HornadyPim;
