export const MainTheme = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00325A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#878472',
      contrastText: '#fff',
    },
    accent: {
      main: '#DAA520',
      contrastText: '#fff',
    },
    background: {
      default: '#FBFEF9',
      paper: '#FBFEF9',
    },
    text: {
      primary: 'rgba(35,34,34,0.87)',
    },
    save: {
      main: '#2E7D32',
      contrastText: '#fff',
    },
    delete: {
      main: '#CB351A',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: 'Lato',
  },
  deminsions: {
    maxWidth: '1600px',
  },
};
