import { MenuItem, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

const VendorDropDown = ({ vendorData, setEntityId, saveVendorValueToContract }) => {
  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [vendorDataState, setVendorDataState] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      setVendorDataState([{ entity_id: 0, business_name: '-' }, ...(await vendorData)]);

      setLoading(false);
    };
    fetch();
  }, [vendorData]);

  if (loading) {
    return null;
  }

  return (
    <TextField
      fullWidth
      select
      label='Choose Vendor'
      defaultValue='0'
      onChange={(e) => {
        setEntityId(e.target.value);
        saveVendorValueToContract(e.target.value);
      }}
    >
      {vendorDataState.map((ven, idx) => (
        <MenuItem key={idx} value={ven.entity_id}>
          {ven.business_name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default VendorDropDown;
