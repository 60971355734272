import * as React from 'react';
import { Box, Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import dayjs from 'dayjs';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

export default function ComplexCard({
  children,
  title,
  subheaderLabel,
  subheader,
  descripition,
  picref,
  avatarColor,
  avatarIcon,
  item,
}) {
  // INITS
  const theme = useTheme();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  console.log('item: ', item);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        sx={{
          '.MuiCardHeader-avatar': { alignSelf: 'flex-start' },
          '.MuiCardHeader-content': { display: 'flex', flexDirection: 'column' },
          '.MuiCardHeader-title': { display: 'none' },
          '.MuiCardHeader-subheader': {
            fontWeight: 'bold',
          },
          '.MuiCardHeader-subheader-label': {
            color: avatarColor ? avatarColor : theme.palette.primary.main,
          },
        }}
        avatar={
          <Avatar
            sx={{
              bgcolor: avatarColor ? avatarColor : theme.palette.primary.main,
              alignSelf: 'flex-start',
            }}
            aria-label='recipe'
          >
            {avatarIcon ? <Icon sx={{ color: 'white' }}>{avatarIcon}</Icon> : title[0]}
          </Avatar>
        }
        action={
          <IconButton aria-label='settings'>
            {/* <MoreVertIcon /> */}
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label='show more'
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </IconButton>
        }
        title={''}
        subheader={
          <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
            {subheader}
            <span className='MuiCardHeader-subheader-label'>{subheaderLabel}</span>
          </Box>
        }
      />
      <CardMedia
        component='img'
        sx={{
          width: `calc(100% - 32px)`,
          borderRadius: 1,
          mx: 'auto',
          boxShadow: '2px 4px 8px rgba(0,0,0,.3)',
        }}
        image={picref}
        alt='Paella dish'
      />
      <Box sx={{ fontWeight: 'bold', px: 2, py: 2, fontSize: '1.2em', minHeight: '74px' }}>
        {title}
      </Box>

      {/* <CardMedia component='img' width='100%' image={picref} alt='Paella dish' /> */}
      {/* <CardContent>
        <Typography variant='body2' sx={{ color: 'text.secondary' }}>
          {descripition} {avatarIcon}
        </Typography>
      </CardContent> */}
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Typography sx={{ mb: 2 }}>{children}</Typography>
        </CardContent>
      </Collapse>
      <CardActions disableSpacing>
        <IconButton aria-label='add to favorites'>
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label='share'>
          <ShareIcon />
        </IconButton>
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
        <Button sx={{ ml: 'auto' }} variant='contained' color='success'>
          Purchase
        </Button>
      </CardActions>
    </Card>
  );
}
