import React, { useContext } from 'react';
import { PublicationContextV2State } from '../../../../../../Context/Marketing/PublicationContextV2';
import { Box, Chip, useTheme } from '@mui/material';

const colors = [
  { id: 1, color: '#3aadff' }, // Blue
  { id: 2, color: '#03673b' }, // Green
  { id: 3, color: '#e89302' }, // Orange
  { id: 4, color: '#dd2a2a' }, // Red
  { id: 5, color: '#831287' }, // Purple
  { id: 6, color: '#487AC0' }, // Blue
  { id: 7, color: '#03673b' }, // Green
  { id: 8, color: '#D3B83A' }, // Orange
  { id: 9, color: '#F70234' }, // Red
  { id: 10, color: '#A62883' }, // Purple
  { id: 11, color: '#102EE2' }, // Blue
  { id: 12, color: '#19FD32' }, // Green
  { id: 13, color: '#F59C27' }, // Orange
  { id: 14, color: '#F33B39' }, // Red
  { id: 15, color: '#A30AA5' }, // Purple
];

const TitleDetails = ({ details }) => {
  // INIT'S
  const theme = useTheme();

  // CONTEXT STATE
  const { allPublicationTypes } = useContext(PublicationContextV2State);

  console.log('details: ', allPublicationTypes);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: '16px',
        alignItems: 'flex-start',
        width: '100%',
        maxWidth: '400px',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ color: theme.palette.primary.alt }}>
        {
          allPublicationTypes.find(
            (e) => e.ss_publication_type_id === details.ss_publication_type_id
          ).description
        }
        {/* <Chip
					sx={{
						textTransform: 'uppercase',
						backgroundColor: colors.find((e) => e.id === details.ss_publication_type_id).color,
						fontWeight: 'bold',
						color: 'White',
					}}
					label={
						allPublicationTypes.find((e) => e.ss_publication_type_id === details.ss_publication_type_id)
							.description
					}
				/> */}
      </Box>
      <Box sx={{ fontWeight: 'bold', fontSize: '1.2em', marginBottom: 1 }}>
        {details.description}
      </Box>
      <Box sx={{ textWrap: 'wrap' }}>{details.detailed_description}</Box>
    </Box>
  );
};

export default TitleDetails;
