import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EntityContext from '../Context/Entity/EntityContext';
import Manufacturers from '../Components/Manufacturers/Manufacturers';
import Advertising from '../Components/Manufacturers/components/Advertising/Advertising';
import Dashboard from '../Components/Manufacturers/components/Dashboard/Dashboard';
import MediaKit from '../Components/Manufacturers/components/MediaKit/MediaKit';
import PublicationContextV2 from '../Context/Marketing/PublicationContextV2';
import PublicationLandingPage from '../Components/Manufacturers/components/MediaKit/components/PublicationLandingPage/PublicationLandingPage';
import PublicationDetails from '../Components/Publications/components/PublicationDetails/PublicationDetails';
const EntityRoutes = () => {
  //const userPermission = useSelector((state) => state.userPermission.value);

  return (
    <EntityContext>
      <PublicationContextV2>
        <Routes>
          <Route path='/advertising' element={<Advertising />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/media-kit' element={<MediaKit />} />
          <Route path='/media-kit/:pub_id' element={<PublicationLandingPage />} />
          <Route path='/view-publication/:pub_id' element={<PublicationDetails />} />
          <Route path='/*' element={<Manufacturers />} />
        </Routes>
      </PublicationContextV2>
    </EntityContext>
  );
};

export default EntityRoutes;
