import React from 'react';
import { useParams } from 'react-router-dom';

const PublicationLandingPage = () => {
  // INIT's
  let { pub_id } = useParams();

  return <div>landing page</div>;
};

export default PublicationLandingPage;
