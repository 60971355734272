import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';

const DirectoryEmailLayout = ({ details }) => {
  const { contact_name, business_name, entity_type, buyer, mfr_rep, notes, issueDescription } =
    details;

  const userLastName = useSelector((state) => state.userInformation.value.lastName);
  const userFirstName = useSelector((state) => state.userInformation.value.firstName);

  const content = (
    <div
      style={{
        fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
        color: '#333',
        lineHeight: '1.6',
        backgroundColor: '#f7f7f7',
        padding: '20px',
      }}
    >
      <div
        style={{
          maxWidth: '600px',
          margin: '0 auto',
          backgroundColor: '#fff',
          borderRadius: '8px',
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
          overflow: 'hidden',
        }}
      >
        {/* Header */}
        <div
          style={{
            backgroundColor: '#2471A3',
            color: '#fff',
            padding: '20px',
            textAlign: 'center',
          }}
        >
          <h2 style={{ margin: 0, fontSize: '24px' }}>Directory Issue Report</h2>
        </div>

        {/* Content Section */}
        <div style={{ padding: '20px', backgroundColor: '#fff' }}>
          <p style={{ fontSize: '16px', marginBottom: '16px' }}>
            {userFirstName} {userLastName} has reported the following issue in the directory:
          </p>

          {/* Business or Contact Information */}
          <div
            style={{
              marginBottom: '20px',
              border: '1px solid #ddd',
              borderRadius: '8px',
              padding: '16px',
              backgroundColor: '#fafafa',
            }}
          >
            {contact_name ? (
              <>
                <p style={{ margin: '8px 0' }}>
                  <strong>Contact Name:</strong> {contact_name}
                </p>
                <p style={{ margin: '8px 0' }}>
                  <strong>Business Name:</strong> {business_name || 'N/A'}
                </p>
              </>
            ) : (
              <>
                <p style={{ margin: '8px 0' }}>
                  <strong>Business Name:</strong> {business_name}
                </p>
                <p style={{ margin: '8px 0' }}>
                  <strong>Entity Type:</strong> {entity_type || 'N/A'}
                </p>
              </>
            )}

            {buyer && (
              <p style={{ margin: '8px 0' }}>
                <strong>Buyer:</strong> {buyer}
              </p>
            )}
            {mfr_rep && (
              <p style={{ margin: '8px 0' }}>
                <strong>Manufacturer Rep:</strong> {mfr_rep}
              </p>
            )}
            {notes && (
              <p style={{ margin: '8px 0' }}>
                <strong>Notes:</strong> {notes}
              </p>
            )}
          </div>

          {/* Reported Issue Section */}
          <div
            style={{
              marginBottom: '20px',
              padding: '16px',
              backgroundColor: '#fff',
              border: '2px dashed #2471A3',
              borderRadius: '8px',
            }}
          >
            <p style={{ fontStyle: 'italic', fontSize: '16px', margin: 0 }}>
              <strong>Reported Issue:</strong> {issueDescription}
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return ReactDOMServer.renderToStaticMarkup(content);
};

export default DirectoryEmailLayout;
