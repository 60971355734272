import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
//import { getAllSubBrandsByEntityId } from '../../../../_GlobalFunctions/entityFunctions';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// PRIMARY COMPONENT
const BrandDropDown = ({ brandData, func, vendorId }) => {
  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState([]);
  const [subBrandState, setSubBrandState] = useState([]);

  // FUNCTIONS
  const handleBrandSelection = (event) => {
    const {
      target: { value },
    } = event;
    setValue(typeof value === 'string' ? value.split(',') : value);
    func(value.join(','));
  };

  useEffect(() => {
    const fetch = async () => {
      setSubBrandState(await brandData);
    };
    fetch();
    setLoading(false);
  }, [brandData]);

  useEffect(() => {
    setValue([]);
  }, [vendorId]);

  if (loading) {
    return <div>...loading</div>;
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <InputLabel id='demo-multiple-name-label'>Choose Brand</InputLabel>
      <Select
        disabled={brandData.length > 0 ? false : true}
        labelId='demo-multiple-name-label'
        multiple
        value={value}
        onChange={(e) => handleBrandSelection(e)}
        input={<OutlinedInput label='Choose Brand' fullWidth />}
        MenuProps={MenuProps}
      >
        {subBrandState.map((brand, idx) => (
          <MenuItem key={idx} value={brand.entity_id}>
            {`${brand.business_name} `}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default BrandDropDown;
