import React, { useEffect, useState } from 'react';
import axios from 'axios';

// CONTEXT STATE
export const EntityContactContextState = React.createContext({
  allEntityContacts: [],
  setAllEntityContacts: () => {},

  allAssociationLink: [],
  setAllAssociationLink: () => {},

  allEmails: [],
  setAllEmails: () => {},

  allPhoneNumbers: [],
  setAllPhoneNumbers: () => {},

  allPhoneTypes: [],
  setAllPhoneTypes: () => {},
});

const EntityContactContext = ({ children }) => {
  // LOCAL STATE
  const [allEntityContactsState, setAllEntityContactsState] = useState([]);
  const [allAssociationLinkState, setAllAssociationLinkState] = useState([]);
  const [allEmailsState, setAllEmailsState] = useState([]);
  const [allPhoneNumbersState, setAllPhoneNumbersState] = useState([]);
  const [allPhoneTypesState, setAllPhoneTypesState] = useState([]);

  // FUNCTION
  const setAllEntityContacts = async () => {
    try {
      const response = await axios.get(`/entity-contacts/getAllEntityContacts`);
      setAllEntityContactsState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const setAllAssociationLink = async () => {
    try {
      const response = await axios.get(`/entity-contacts/getAllAssociaionLinks`);
      setAllAssociationLinkState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const setAllEmails = async () => {
    try {
      const response = await axios.get(`/entity-contacts/getAllEmails`);
      setAllEmailsState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const setAllPhoneNumbers = async () => {
    try {
      const response = await axios.get(`/entity-contacts/getAllPhoneNumbers`);
      setAllPhoneNumbersState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  const setAllPhoneTypes = async () => {
    try {
      const response = await axios.get(`/entity-contacts/getAllPhoneTypes`);
      setAllPhoneTypesState([...response.data]);
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  useEffect(() => {
    setAllEntityContacts();
    setAllAssociationLink();
    setAllEmails();
    setAllPhoneNumbers();
    setAllPhoneTypes();
  }, []);

  // CONTEXT OBJECT TO PASS THROUGH PROVIDER
  const contextValue = {
    allEntityContacts: allEntityContactsState,
    setAllEntityContacts,
    allAssociationLink: allAssociationLinkState,
    setAllAssociationLink,
    allEmails: allEmailsState,
    setAllEmails,
    allPhoneNumbers: allPhoneNumbersState,
    setAllPhoneNumbers,
    allPhoneTypes: allPhoneTypesState,
    setAllPhoneTypes,
  };
  console.log('allAssociationLink: ', allAssociationLinkState);
  return (
    <EntityContactContextState.Provider value={contextValue}>
      {children}
    </EntityContactContextState.Provider>
  );
};

export default EntityContactContext;
