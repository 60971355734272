import { Route, Routes } from 'react-router-dom';
import React from 'react';
import ProductDataDashboard from '../Components/ProductDataManager/Dashboard/Dashboard';
import ProductDataContext from '../Context/Marketing/ProductData/ProductDataContext';
import ProductDataGeneralTable from '../Components/ProductDataManager/GeneralTable/GeneralTable';
import ValueCorrections from '../Components/ProductDataManager/Archive/Components/ValueCorrections/ValueCorrections';

const ProductDataManagerRoute = () => {
  return (
    <ProductDataContext>
      <Routes>
        <Route path='/data-results/:type/:value' element={<ProductDataGeneralTable />} />

        <Route path='/*' element={<ProductDataDashboard />} />
      </Routes>
    </ProductDataContext>
  );
};

export default ProductDataManagerRoute;
