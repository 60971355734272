import React, { useEffect, useState } from 'react';
import DashboardCards from './components/DashboardCards';
//import { products } from '../../../ProductDataManager/ServiceWorkers/ProductServiceWorker';
import { Box, Button, Container } from '@mui/material';
import ComplexCard from '../../../_GlobalComponents/Cards/ComplexCard';
import { removeAndApplyAttributeValue } from '../../../_GlobalFunctions/pdm';

const descripition =
  'The 2025 Media Kit offers extensive marketing opportunities designed to help businesses increase exposure and reach their target audience.';

const Dashboard = () => {
  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return <div>... Loading</div>;
  }

  console.log('data: ', data);

  const handleClick = async () => {
    const res = await removeAndApplyAttributeValue(88323, 71159, '291550, 283209');
    console.log('res from removing: ', res);
  };

  return (
    <>
      <DashboardCards />
      <Container sx={{ mx: 'auto', my: 4 }} maxWidth='xl'>
        Data Has <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>{data.length}</span> Rows!
      </Container>
      <Container
        sx={{
          my: 4,
          mx: 'auto',
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '1fr 1fr',
            lg: '1fr 1fr 1fr',
            xl: '1fr 1fr 1fr 1fr',
          },
          gap: 3,
        }}
        maxWidth='xl'
      >
        {data.map((item, idx) => (
          <ComplexCard
            key={idx}
            title={item.as400_description}
            picref={item.picture_reference}
            subheader='Take advantage of our marketing opportunities!'
            descripition={item.description}
          >
            {item.upc_code} |{item.detailed_description}
          </ComplexCard>
        ))}
      </Container>
      <Button variant='contained' onClick={() => handleClick()}>
        Update
      </Button>
    </>
  );
};

export default Dashboard;
