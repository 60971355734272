import React from 'react';
import { Box } from '@mui/material';
//import ContractForm from '../Contracts/components/ManageContracts/components/ContractForm';
//import ContractFormMobile from '../Contracts/components/ManageContracts/components/ContractFormMobile';

const Manufacturers = () => {
  return (
    <Box sx={{ height: '100%', backgroundColor: '#e7e7e7', overflow: 'hidden' }}>
      {/* <ContractForm userid={54155} /> */}
      {/* <ContractFormMobile userid={54155} /> */}
      {[...Array(10)].map((e, index) => (
        <p key={index} style={{ width: '300px', padding: '16px' }}>
          {index} Manufacturers
        </p>
      ))}
    </Box>
  );
};

export default Manufacturers;
