import { Box, Grid2, MenuItem, Paper, TextField, useTheme } from '@mui/material';

import {
  getAllSubBrandsByEntityId,
  getAllParentEntitiesByContactId,
} from '../../../../_GlobalFunctions/entityFunctions';
import React, { useEffect, useState } from 'react';

import dayjs from 'dayjs';
import CallToAction from './CallToAction';
import MoreInfoSlide from './MoreInfoSlide';
import BrandDropDown from './BrandDropDown';
import VendorDropDown from './VendorDropDown';

const PublicationCard = ({ item, pubProducts }) => {
  // INIT's
  const theme = useTheme();

  // STYLES
  const paperStyles = {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    pb: 2,
    height: '100%',
    border: `0px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
  };

  const typeStyles = {
    backgroundColor: theme.palette.accent.main,
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '.9em',
    textTransform: 'uppercase',
    py: 1,
  };

  // LOCAL STATE;
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [vendorId, setVendorId] = useState(0);
  const [entityId, setEntityId] = useState();
  const [pubProductsState, setPubProductsState] = useState([]);
  const [contractData, setContractData] = useState({});
  const [brandDataState, setBrandDataState] = useState([]);

  // FUNCTION
  const handleOpeningInfo = (value) => {
    setOpen(value);
  };

  const handleSavingContractOption = (e) => {
    const cost = pubProductsState.find(
      (pub) => pub.ss_publication_product_id === e.target.value
    ).standard_price;
    setContractData((prev) => ({
      ...prev,
      ss_publication_product_id: e.target.value,
      sold_price: cost,
    }));
  };

  const saveVendorValueToContract = (value) => {
    setContractData((prev) => ({ ...prev, entity_id: value, product_manufacturers: '' }));
    setBrandDataState([]);
    setVendorId(vendorId + 1);
  };

  const saveBrandValueToContract = (value) => {
    setContractData((prev) => ({ ...prev, product_manufacturers: value }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await pubProducts;
        setPubProductsState((prev) => [
          { ss_publication_product_id: 0, description: '', standard_price: '' },
          ...res.filter(
            (e) =>
              e.types !== null && e.types.split(',').includes(String(item.ss_publication_type_id))
          ),
        ]);

        setContractData((prev) => ({
          ...prev,
          ss_publication_id: item.ss_publication_id,
          user_id: 54155,
        }));

        const parentEntities = await getAllParentEntitiesByContactId(373);
        const subBrands = await getAllSubBrandsByEntityId(entityId);
        setVendorData(parentEntities);
        setBrandDataState(subBrands);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(true);
      }
    };
    fetchData();
  }, [item.ss_publication_id, item, pubProducts, entityId]);

  if (loading) {
    return '... Loading';
  }

  return (
    <Grid2 size={1} sx={{ minHeight: 0 }}>
      <Paper sx={{ ...paperStyles }} elevation={4}>
        <MoreInfoSlide item={item} open={open} handleOpeningInfo={handleOpeningInfo} />
        <Box>
          <img
            style={{ width: '100%', display: 'block' }}
            src='/images/publications/turkey_hunting_large.jpg'
            alt=''
          />
          <Box sx={{ ...typeStyles }}>{item.publication_type}</Box>
        </Box>

        <Box sx={{ px: 1 }}>
          <Box sx={{ fontWeight: 'bold', color: theme.palette.grey[500] }}>
            {dayjs(item.start_date).format('MMM, YYYY')}
          </Box>
          <Box sx={{ fontWeight: 'bold', fontSize: '1.2em' }}>{item.description}</Box>
        </Box>

        <Box sx={{ px: 1, flex: 1 }}>{item.detailed_description}</Box>
        <Box sx={{ p: 2 }}>
          <TextField
            fullWidth
            select
            label='Choose Option'
            defaultValue='0'
            onChange={(e) => handleSavingContractOption(e)}
          >
            {pubProductsState.map((prod, idx) => (
              <MenuItem key={idx} value={prod.ss_publication_product_id}>
                {`${prod.description} - ${prod.standard_price}`}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        <Box sx={{ p: 2, pt: 0 }}>
          <VendorDropDown
            vendorData={vendorData}
            setEntityId={setEntityId}
            saveVendorValueToContract={saveVendorValueToContract}
          />
        </Box>
        <Box sx={{ p: 2, pt: 0 }}>
          <BrandDropDown
            brandData={brandDataState}
            func={saveBrandValueToContract}
            vendorId={vendorId}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            alignItems: 'center',
            justifyContent: 'space-between',

            // border: `solid 2px ${theme.palette.primary.main}`,
            // backgroundColor: `${theme.palette.primary.main}44`,
            p: 1,
          }}
        >
          <CallToAction
            item={item}
            handleOpeningInfo={handleOpeningInfo}
            contractData={contractData}
          />
        </Box>
      </Paper>
    </Grid2>
  );
};

export default PublicationCard;
