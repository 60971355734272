import { ExpandLess, KeyboardArrowDown } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import React, { useState } from 'react';
import SideNav from './SideNav';

const MobileFilter = () => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [open, setOpen] = useState(false);

  return (
    <Box
      sx={{
        display: { xs: 'block', md: 'none' },
        position: 'fixed',
        bottom: '0',
        backgroundColor: open ? '#ffffffdd' : `${theme.palette.primary.alt}dd`,
        borderTopLeftRadius: '0px',
        borderTopRightRadius: '0px',
        backdropFilter: 'blur(4px)',
        height: open ? 'calc(100dvh - 54px)' : '50px',
        width: '100vw',
        zIndex: 1300,
        transition: '100ms ease-in-out',
      }}
    >
      <Box
        sx={{
          textAlign: 'center',
          backgroundColor: open ? 'transparent' : theme.palette.primary.main,
        }}
      >
        <IconButton onClick={() => setOpen(!open)}>
          {open ? (
            <KeyboardArrowDown sx={{ fontSize: '1.6em', color: theme.palette.delete.main }} />
          ) : (
            <ExpandLess sx={{ fontSize: '1.6em', color: 'white' }} />
          )}
        </IconButton>
      </Box>
      <Box sx={{ p: 4, pt: 0 }}>
        <SideNav />
      </Box>
    </Box>
  );
};

export default MobileFilter;
