import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

export const MediaKitContextState = React.createContext({
  year: new Date().getFullYear(),
  setYear: () => {},
  month: 0,
  setMonth: () => {},
  type: 0,
  setType: () => {},
  jsonXls: '',
  setJsonXls: () => {},
});
const MediaKitContext = ({ children }) => {
  //LOCAL STATE

  const [yearState, setYearState] = useState(new Date().getFullYear());
  const [monthState, setMonthState] = useState(0);
  const [typeState, setTypeState] = useState(0);
  const [jsonXlsState, setJsonXlsState] = useState('');

  const contextValue = {
    year: yearState,
    setYear: setYearState,
    month: monthState,
    setMonth: setMonthState,
    type: typeState,
    setType: setTypeState,
    jsonXls: jsonXlsState,
    setJsonXls: setJsonXlsState,
  };

  return (
    <MediaKitContextState.Provider value={contextValue}>{children}</MediaKitContextState.Provider>
  );
};

export default MediaKitContext;
