import { Drawer, ButtonGroup, Button, TextField, Autocomplete, Stack, Paper, InputBase, IconButton } from "@mui/material"
import { Search } from "@mui/icons-material"
import {ProductDataContextState} from "../../../../Context/Marketing/ProductData/ProductDataContext"
import { useContext, useRef } from "react"
import { useNavigate } from "react-router-dom"
export default function DashboardDrawer(props){
    const { allManufacturers, allCategories, allDepartments } = useContext(ProductDataContextState)
    const drawerOpen = props.drawerOpen
    const closeDrawer = props.closeDrawer
    const navigate = useNavigate()

    const CatVal = allCategories
    const BrandVal = allManufacturers
    const DeptVal = allDepartments
    const drawerOptionMargin = 3
    const searchValue = useRef('')

    const handleChange = (type, value) => {
       navigate('/product-data/data-results/'+ type+ '/' + value)
    }

    const submitInfo = () => {
        console.log('submitting')
        console.log(searchValue)
    }


    return(
        <Drawer open={drawerOpen} onClose={closeDrawer} anchor="right">
            <Stack direction={'row'} spacing={0} alignSelf={'center'}>
            <Paper sx={{ paddingLeft: '12px', display: 'flex', alignItems: 'center' }}>
              <InputBase
                placeholder='Search'
                aria-label='Search'
                onInput={(event) => {
                  searchValue.current = event.target.value;
                }}
                onKeyUp={(event) => {
                  if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                    console.log('keyUp');
                    handleChange('fuzzy', searchValue.current)
                  }
                }}
              />
              <IconButton
                onClick={() => {
                  handleChange('fuzzy', searchValue.current)
                }
                }
              >
                <Search />
              </IconButton>
            </Paper>
          </Stack>
            <ButtonGroup orientation="vertical" variant="text" sx={{paddingLeft:2, paddingRight:2}}>
                <Button href="/product-data/data-results/nocategory/0">
                    Uncategorized Items
                </Button>
                <Button>
                    Unattributed Items
                </Button>
                <Button href="/product-data/data-results/worn/0">
                    Worn & Dented
                </Button>
                <Button>
                    My Flagged Items
                </Button>
                <Button>
                    All Flagged Items
                </Button>
                <Button>
                    Items w/ Few Attributes
                </Button>
                <Button href="/product-data/data-results/nocoo/0">
                    Items Without Country set
                </Button>
                <Button href="/product-data/data-results/notv/0">
                    Missing True Value
                </Button>
                <Button>
                    Missing Max Capacity
                </Button>
            </ButtonGroup>
            <TextField label='Search For Recently Worked' variant="outlined"/>
            <Autocomplete
                getOptionKey={(dept) => dept.product_department_id}
                getOptionLabel={(dept) => dept.description}
                sx={{marginTop: drawerOptionMargin}}
                options={DeptVal}
                renderInput={(params) => <TextField {...params} label="Department"/>}
                onChange={(e, val) => handleChange('dept', val.product_category_id)}
            />
            <Autocomplete                
                getOptionKey={(cat) => cat.product_category_id}
                getOptionLabel={(cat) => cat.description}
                sx={{marginTop: drawerOptionMargin}}
                options={CatVal}
                renderInput={(params) => <TextField {...params} label="Category"/>}
                onChange={(e, val) => handleChange('category', val.product_category_id)}
            />
            <Autocomplete     
                getOptionKey={(man) => man.product_manufacturer_id}
                getOptionLabel={(man) => man.description}           
                sx={{marginTop: drawerOptionMargin}}
                options={BrandVal}
                renderInput={(params) => <TextField {...params} label="Brand"/>}
                onChange={(e, val) => handleChange('mfrid', val.product_category_id)}
            />
            <Button sx={{margin:3}} variant="contained">Logo Manager</Button>
            <Button sx={{margin:3}} variant='contained'>Value Corrections</Button>
        </Drawer>
    )
}