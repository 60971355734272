import { Box, Button, Grid2, Paper, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { CalendarMonth } from '@mui/icons-material';

const PublicationCardV3 = ({ item, pubProducts }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);

  const typeStyles = {
    backgroundColor: theme.palette.accent.main,
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '.9em',
    textTransform: 'uppercase',
    py: 1,
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(false);
    };
    fetch();
  }, []);

  if (loading) {
    return <div>...loading</div>;
  }

  return (
    <Grid2 size={1} sx={{ minHeight: 0, backgroundColor: '#e7e7e7' }}>
      <Paper elevation={2} sx={{ display: 'flex', flexDirection: 'column', height: '100%', pb: 2 }}>
        <Box>
          <img
            style={{ width: '100%', display: 'block' }}
            src='/images/publications/turkey_hunting_large.jpg'
            alt=''
          />
          <Box sx={{ ...typeStyles }}>{item.publication_type}</Box>
        </Box>

        <Box
          sx={{
            px: 2,
            mt: 2,
            color: theme.palette.secondary.main,
            display: 'flex',
            gap: 0.5,
            fontSize: '.9em',
          }}
        >
          <CalendarMonth sx={{ fontSize: '1.4em' }} />
          <span style={{ fontWeight: 'bold' }}>
            {dayjs(item.start_date).format('MMM DD, YYYY')}
          </span>
          <span>-</span>
          <span style={{ fontWeight: 'bold' }}>
            {dayjs(item.expiration_date).format('MMM DD, YYYY')}
          </span>
        </Box>
        <Box
          sx={{
            px: 2,
            py: 1,
            color: theme.palette.accent.main,
            fontWeight: 'bold',
            fontSize: '1.4em',
            lineHeight: 1.1,
          }}
        >
          {item.description}
        </Box>
        <Box sx={{ px: 2, flex: 1 }}>{item.detailed_description}</Box>
        <Box sx={{ mt: 2, px: 2 }}>
          <Button fullWidth variant='contained' color='secondary' sx={{ fontWeight: 'bold' }}>
            View Detials
          </Button>
        </Box>
      </Paper>
    </Grid2>
  );
};

export default PublicationCardV3;
