import axios from 'axios';
import dayjs from 'dayjs';

export const dateFormatterYYYYMMDD = (d) => {
  const myDate = new Date(d);
  const myYear = myDate.getFullYear();
  const myMonth = myDate.getMonth() < 10 ? '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
  const myDay = myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate();
  return myYear + '-' + myMonth + '-' + myDay;
};

export const fetchUserData = async (url) => {
  return await axios.get(url).then((response) => {
    return response.data;
  });
};

export const random7DigitNumber = () => {
  return Math.floor(1000000 + Math.random() * 9000000);
};

export async function removeAndApplyAttributeValue(replaceValue = 0, addedValue = 0, items = []) {
  try {
    if (replaceValue === addedValue) {
      return 'can not have matching replace and added values';
    }

    if (replaceValue > 0 && addedValue > 0 && items.length > 0) {
      const response = await axios.post(`/products/removeAndApplyAttributeValue`, {
        old_pfo_id: replaceValue,
        new_pfo_id: addedValue,
        product_id: items,
      });
      return response.data;
    } else {
      console.log('replace function did not run');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

//deletes records from product_facet_values where pfo_id = ? and product_id IN (?)
export const deleteValuesByIds = async (product_id = [], option_id = 0) => {
  const data = await axios.post(`/products/deleteValuesByIds`, {
    product_id: product_id,
    option_id: option_id,
  });
  return data.data;
};

//inserts records into the product_facet_values table
export const insertAttributeValue = async (
  product_id = [],
  facet_id = 0,
  option_id = 0,
  primary_facet = 0
) => {
  const data = await axios.post(`/products/insertAttributeValue`, {
    product_id: product_id,
    product_facet_id: facet_id,
    pfo_id: option_id,
    primary_facet: primary_facet,
  });
  return data.data;
};

//get
export const getFacetsByCategory = async (cat_id) => {
  const data = await axios.get(`/products/getFacetsByCategory/${cat_id}`);
  return data.data;
};

export const getOptionsByFacet = async (facet_id) => {
  const data = await axios.get(`/products/getOptionsByFacet/${facet_id}`);
  return data.data;
};

export const getProductsByFacets = async (facet_id, option_id) => {
  const data = await axios.get(`/products/getProductsByFacets/${facet_id}/${option_id}`);
  return data.data;
};
