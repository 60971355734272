import { Route, Routes } from 'react-router-dom';
import HornadyPim from '../Components/Marketing/component/SandBox/HornadyPim/HornadyPim';

const SandBoxRoutes = () => {
  return (
    <Routes>
      <Route path='/hornady-pim' element={<HornadyPim />} />

      <Route path='/*' element={<h4>SandBox Stuff</h4>} />
    </Routes>
  );
};

export default SandBoxRoutes;
