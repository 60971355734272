import React, { useEffect, useState, Suspense } from 'react';
import ReactDOMServer from 'react-dom/server.browser';
import { Html, Section } from '@react-email/components';
import { Button } from '@mui/material';
import axios from 'axios';

import Block1010 from '../Blocks/Block1010';
import Footer1000 from '../Blocks/Footer1000.jsx';
import { sendContractCreatedEmail } from '../../../_GlobalFunctions/publicationFunctions.js';
import Header1000 from '../Blocks/Header1000.jsx';
import TestEditor from './TestEditor.jsx';

const emailData = {
  email_id: 108924,
  email_name: 'Weekly Email November 08, 2024',
  subject_line: 'This Weeks Specials!',
  audience: ['tony.ocon@sportssouth.biz', 'tony.ocon@oconfamily.net'],
  from: 'tony.ocon@oconfamily.net',
  blocks: [
    {
      block_id: 'Block1010',
      block_instance_id: 334567,
      sub_header: 'Feature Products',
      header: 'Check-out our feature items!',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
      products: [73201, 8037, 13704, 170903, 170904, 169135],
    },
    {
      block_id: 'Block1010',
      block_instance_id: 334568,
      sub_header: 'Top Selling Products',
      header: 'Check-out these top selling items!',
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation',
      products: [170903, 170904, 169135],
    },
  ],
};

const EmailEditor = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState('');

  const loadComponent = (componentName) => {
    return React.lazy(() => import(`../Blocks/Block1010`));
  };

  const handleClick = async () => {
    const emailObj = {
      emailAddress: 'tony.ocon@oconfamily.net, tony.ocon@sportssouth.biz',
      subject: `Ad builder sandbox mail`,
      htmlContent: `<Html>
          <Header1000 />
          <Section
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: 16,
              marginBottom: 16,
              maxWidth: '600px',
            }}
          >
            {emailData.blocks.map((block, idx) => (
              <Block1010 block={block} />
            ))}
          </Section>
          <Footer1000 />
        </Html>`,
    };

    const res = await sendContractCreatedEmail(emailObj);

    console.log('email res: ', res);
  };

  useEffect(() => {
    const getMarkup = async () => {
      try {
        const response = await axios.get(`/adBuilder/getMarkup/block1008`);
        const markup = <div dangerouslySetInnerHTML={{ __html: response.data }} />;
        console.log('markup: ', markup.dangerouslySetInnerHTML.__html);
        // setData(markup);
        setLoading(false);
        // return response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
        return error;
      }
    };
    getMarkup();
  }, []);

  if (loading) {
    <div>...loading</div>;
  }
  return (
    <>
      <Button variant='contained' onClick={() => handleClick()}>
        Send Email
      </Button>
      {/* <TestEditor /> */}
    </>
  );
};

export default EmailEditor;
