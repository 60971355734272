import { Box, Grid2 } from '@mui/material';
import React from 'react';

const DashboardCardWrapper = ({ children, bgcolor = 'white' }) => {
  return (
    <Grid2
      sx={{
        backgroundColor: bgcolor,
        border: 'solid 2px rgb(220,220,220)',
        boxShadow: '0px 3px 9px rgba(0,0,0,.2)',
        borderRadius: '16px',
      }}
      size={{ xs: 12, sm: 6, md: 4, xl: 3 }}
    >
      {children}
    </Grid2>
  );
};

export default DashboardCardWrapper;
