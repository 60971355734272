import { Close } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

const MoreInfoSlide = ({ item, open, handleOpeningInfo }) => {
  // INIT's
  const theme = useTheme();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const obj = [
      {
        label: 'Contract Due',
        date: dayjs(item.submit_contract_date).format('MM-DD-YYYY'),
      },
      {
        label: 'Art Work Due',
        date: dayjs(item.submit_art_date).format('MM-DD-YYYY'),
      },
      {
        label: 'Start',
        date: dayjs(item.start_date).format('MM-DD-YYYY'),
      },
      {
        label: 'Expiration',
        date: dayjs(item.expiration_date).format('MM-DD-YYYY'),
      },
    ];
    setDates(obj);
    setLoading(false);
  }, [item]);

  if (loading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background:
          'linear-gradient(196deg, rgba(255,255,255,.9) 0%,  rgba(255,255,255,.8) 48%, rgba(255,255,255,.9) 76%, rgba(255,255,255,1) 100%)',
        backdropFilter: 'blur(5px)',
        position: 'absolute',
        left: 0,
        top: open ? 0 : '100%',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        zIndex: 3,
        boxSizing: 'content-box',
        transition: '300ms cubic-bezier(.25,-0.3,.54,1.34)',
      }}
    >
      <Box
        sx={{ flex: 1, overflowY: 'auto', mb: 2, px: 2, display: 'flex', flexDirection: 'column' }}
      >
        <Box sx={{ flex: 1, overflow: 'auto', display: 'flex', flexDirection: 'column', gap: 1 }}>
          {/* <img
            style={{ width: '100%', display: 'block' }}
            src='/images/publications/turkey_hunting_large.jpg'
            alt=''
          /> */}
          <Box sx={{ fontWeight: 'bold', fontSize: '1.4em', color: theme.palette.primary.main }}>
            {item.description}
          </Box>
          <Box>{item.detailed_description}</Box>
          <Box sx={{ textAlign: 'center', mt: 4, flex: 1 }}>
            {dates.map((date, idx) => (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  gap: 1,
                  fontWeight: 'bold',
                  fontSize: '1.1em',
                }}
              >
                <span style={{ textAlign: 'right', color: theme.palette.primary.main, flex: 1 }}>
                  {date.label}
                </span>
                <span style={{}}>:</span>
                <span style={{ flex: 1, textAlign: 'left' }}>{date.date}</span>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: '1.1em',
              color: theme.palette.accent.main,
              mb: 2,
            }}
          >
            Mailers are sent to approxiametly 12,000 active, managed dealers, as well as several
            hundred lead FFL holders.
          </Box>
        </Box>
        <Box sx={{ textAlign: 'left' }}>
          <IconButton onClick={() => handleOpeningInfo(false)}>
            <Close
              sx={{ color: theme.palette.delete.main, fontWeight: 'bold', fontSize: '1.5em' }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default MoreInfoSlide;
