import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

const CreditModal = ({ open, onClose, hotels = [], hotelDays = [], onSave }) => {
  const [creditDetails, setCreditDetails] = useState({
    hotel: '',
    otherHotel: '',
    checkInDay: '',
    checkOutDay: '',
    creditAmount: '',
    notes: '',
  });
  const [showErrors, setShowErrors] = useState({
    hotel: false,
    otherHotel: false,
    checkInDay: false,
    checkOutDay: false,
    creditAmount: false,
  });
  const [axiosError, setAxiosError] = useState('');

  useEffect(() => {
    if (open) {
      setCreditDetails({
        hotel: '',
        otherHotel: '',
        checkInDay: '',
        checkOutDay: '',
        creditAmount: '',
        notes: '',
      });
      setShowErrors({
        hotel: false,
        otherHotel: false,
        checkInDay: false,
        checkOutDay: false,
        creditAmount: false,
      });
      setAxiosError('');
    }
  }, [open]);

  const handleSave = () => {
    setAxiosError('');
    onSave(creditDetails)
      .then((response) => {
        onClose();
      })
      .catch((error) => {
        setAxiosError(
          error?.response?.data?.message ?? 'An error occurred while submitting the credit'
        );
      });
  };

  const checkIsEmpty = (fieldName) => {
    if (!creditDetails[fieldName]) {
      // Field is required and empty, set it to true to indicate error
      setShowErrors({ ...showErrors, [fieldName]: true });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>Apply Credit</DialogTitle>
      <Divider sx={{ mx: 3 }} />
      <DialogContent>
        {axiosError ? (
          <Typography variant='body1' color='error' sx={{ mb: 0.5, textAlign: 'center' }}>
            Error: {axiosError}
          </Typography>
        ) : null}
        <Typography
          variant='body1'
          color={
            Object.values(showErrors).some((value) => value === true) ? 'error' : 'textSecondary'
          }
          sx={{ mb: 1.5 }}
        >
          * Required
        </Typography>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Autocomplete
            value={hotels.find((hotel) => hotel.description === creditDetails.hotel) ?? null}
            onChange={(e, selectedHotel) => {
              setCreditDetails({
                ...creditDetails,
                hotel: selectedHotel?.description ?? '',
              });
              setShowErrors({ ...showErrors, hotel: false, otherHotel: false });
            }}
            onBlur={() => checkIsEmpty('hotel')}
            options={hotels}
            getOptionLabel={(hotel) => hotel.description}
            renderInput={(params) => (
              <TextField {...params} label='Hotel' required error={showErrors.hotel} />
            )}
          />
        </FormControl>
        {creditDetails.hotel === 'Other' && (
          <TextField
            fullWidth
            label='Hotel Name'
            required
            value={creditDetails.otherHotel ?? ''}
            onChange={(e) => {
              setCreditDetails({ ...creditDetails, otherHotel: e.target.value });
              setShowErrors({ ...showErrors, otherHotel: false });
            }}
            onBlur={() => checkIsEmpty('otherHotel')}
            error={showErrors.otherHotel}
            sx={{ mt: 1 }}
          />
        )}
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Autocomplete
            value={hotelDays.find((day) => day.description === creditDetails.checkInDay) ?? null}
            onChange={(e, selectedDay) => {
              setCreditDetails({
                ...creditDetails,
                checkInDay: selectedDay?.description ?? '',
              });
              setShowErrors({ ...showErrors, checkInDay: false });
            }}
            onBlur={() => checkIsEmpty('checkInDay')}
            options={hotelDays}
            getOptionLabel={(day) => day.description}
            renderInput={(params) => (
              <TextField {...params} label='Check-in Day' required error={showErrors.checkInDay} />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <Autocomplete
            value={hotelDays.find((day) => day.description === creditDetails.checkOutDay) ?? null}
            onChange={(e, selectedDay) => {
              setCreditDetails({
                ...creditDetails,
                checkOutDay: selectedDay?.description ?? '',
              });
              setShowErrors({ ...showErrors, checkOutDay: false });
            }}
            onBlur={() => checkIsEmpty('checkOutDay')}
            options={hotelDays}
            getOptionLabel={(day) => day.description}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Check-out Day'
                required
                error={showErrors.checkOutDay}
              />
            )}
          />
        </FormControl>
        <FormControl fullWidth sx={{ mt: 1 }}>
          <TextField
            id='credit-amount'
            label='Total Cost of Hotel'
            value={creditDetails.creditAmount ?? ''}
            placeholder='0.00'
            onChange={(e) => {
              setCreditDetails({ ...creditDetails, creditAmount: e.target.value });
            }}
            onBlur={() => {
              if (
                typeof creditDetails.creditAmount === 'string' &&
                creditDetails.creditAmount.trim() === ''
              ) {
                setCreditDetails({ ...creditDetails, creditAmount: '' });
                setShowErrors({ ...showErrors, creditAmount: true });
              } else {
                const parsedValue = parseFloat(creditDetails.creditAmount || '');
                const newValue = isNaN(parsedValue) ? '' : parsedValue.toFixed(2);
                setCreditDetails({ ...creditDetails, creditAmount: newValue });
                setShowErrors({ ...showErrors, creditAmount: !newValue });
              }
            }}
            error={showErrors.creditAmount}
            InputProps={{
              startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              inputProps: {
                type: 'text',
                inputMode: 'numeric',
                pattern: '[0-9]*',
              },
            }}
          />
        </FormControl>
        <TextField
          fullWidth
          label='Notes'
          value={creditDetails.notes ?? ''}
          onChange={(e) => {
            setCreditDetails({ ...creditDetails, notes: e.target.value });
            // setShowErrors({ ...showErrors, notes: false });
          }}
          // onBlur={() => checkIsEmpty('notes')}
          // error={showErrors.notes}
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            // Check if any required fields are empty
            if (
              Object.keys(showErrors).some(
                (key) =>
                  (key === 'otherHotel' &&
                    creditDetails.hotel === 'Other' &&
                    creditDetails[key] === '') ||
                  (key !== 'otherHotel' && creditDetails[key] === '')
              )
            ) {
              // At least one required field is empty, so show errors
              setShowErrors(
                Object.fromEntries(
                  Object.keys(showErrors).map((key) => [
                    key,
                    key !== 'otherHotel' ||
                    (key === 'otherHotel' && creditDetails.hotel === 'Other')
                      ? creditDetails[key] === ''
                      : false,
                  ])
                )
              );
            } else {
              // No empty required fields, so continue with save
              handleSave();
            }
          }}
          color='primary'
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreditModal;
