import axios from 'axios';

export async function getAllPublication() {
  try {
    const response = await axios.get(`/publications/getAllPublications`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getAllPublicationTypes() {
  try {
    const response = await axios.get(`/publications/getAllPublicationTypes`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function getAllPublicationProducts() {
  try {
    const response = await axios.get(`/publications/getAllPublicationProducts`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function savePubliationContract(obj) {
  try {
    const response = await axios.post(`/contracts/insertContract`, obj);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
}

export async function sendContractCreatedEmail(obj) {
  console.log('sendContractCreatedEmail: was called: ', obj);

  try {
    const response = await axios.post(`/email/publication/contract-confirmation`, obj);
    console.log('sendContractCreatedEmail: ', response);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    return error;
  }
}
