import { Box, Button, Divider, Grid, Modal, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import Moment from 'react-moment';
import PreviewIcon from '@mui/icons-material/Preview';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import React, { useState } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const ResolutionContainer = (data) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [modalData, setModalData] = useState(null);

  const sizeHeaders = () => {
    if (data.data.length === 0) {
      return null;
    } else {
      return (
        <>
          <Grid item xs={3}>
            <Typography variant='h6'>High Resolution</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='h6'>Large</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='h6'>Small</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant='h6'>Thumbnail</Typography>
          </Grid>
        </>
      );
    }
  };

  const sizeCalculator = (pic) => {
    if (pic / 1024 < 1024) {
      return `${Math.round(pic / 1024)} KB`;
    } else if (pic / 1024 / 1024 < 1024) {
      return `${Math.round(pic / 1024 / 1024)} MB`;
    }
  };

  return (
    <Box>
      <Grid container alignContent='center'>
        {sizeHeaders()}
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'lightgreen',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              maxWidth: 400,
            }}
          >
            <img
              src={`https://media.server.theshootingwarehouse.com/${modalData}`}
              alt='preview'
              style={{ width: '100%' }}
            />
          </Box>
        </Modal>
        {data.data.map((image, index) => {
          return (
            <Grid item xs={3} key={index}>
              {image.map((image, index) => {
                return (
                  <>
                    <Box key={index} sx={{ paddingBottom: 2, paddingTop: 2, textAlign: 'center' }}>
                      <Grid container justifyContent={'center'}>
                        <Grid item xs={12}>
                          <Typography variant='h6'>
                            {image.Key ? (
                              <CheckIcon sx={{ color: 'green', fontSize: 40 }} />
                            ) : (
                              <ClearIcon sx={{ color: 'red', fontSize: 40 }} />
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            onClick={() => {
                              setModalData(image.Key);
                              handleOpen();
                            }}
                          >
                            <PreviewIcon />
                          </Button>
                          <Typography variant='overline'>Preview</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            onClick={() => {
                              window.open(
                                `https://media.server.theshootingwarehouse.com/${image.Key}`,
                                '_blank'
                              );
                            }}
                          >
                            <OpenInNewIcon />
                          </Button>
                          <Typography variant='overline'>Open</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <CopyToClipboard
                            text={`https://media.server.theshootingwarehouse.com/${image.Key}`}
                          >
                            <Button>
                              <ContentCopyIcon />
                            </Button>
                          </CopyToClipboard>
                          <Typography variant='overline'>Copy</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='overline' sx={{ fontWeight: 600 }}>
                            File: {<Typography variant='overline'>{image.Key}</Typography>}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='overline' sx={{ fontWeight: 600 }}>
                            Image Size:{' '}
                            {
                              <Typography variant='overline'>
                                {image.Size ? sizeCalculator(image.Size) : 'N/A'}
                              </Typography>
                            }
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='overline' sx={{ fontWeight: 600 }}>
                            Last Modified:{' '}
                            {
                              <Typography variant='overline'>
                                {<Moment format='L'>{image.LastModified}</Moment>}
                              </Typography>
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                    <Divider />
                  </>
                );
              })}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ResolutionContainer;
