import React, { useContext, useEffect, useState } from 'react';
import { PublicationContextState } from '../../../../Context/Marketing/PublicationContext';
import { useParams } from 'react-router-dom';
import useColumns from './DashBoardTableColumns';
import { getContractsByPublicationId } from '../ManageContracts/Feeds/ContractsFeed';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ShowHideColumnsButton as MrtShowHideColumnsButton,
  MRT_GlobalFilterTextField as MrtGlobalFilterTextField,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
} from 'material-react-table';
import { Box, Button, useTheme } from '@mui/material';
import ContainerTitle from '../../../Entities/components/ContainerTitle';
import CreateNewContractButton from '../ManageContracts/components/CreateNewContractButton';

const DashBoardTable = () => {
  const columns = useColumns();
  const { id, title } = useParams();

  // LOCAL STATE
  const [data, setData] = useState([]);

  // CONTEXT DATA
  const { triggerRefreshContractData } = useContext(PublicationContextState);

  const theme = useTheme();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const results = await getContractsByPublicationId(id);
      setData(results);
      setLoading(false);
    };
    fetchData();
  }, [id, triggerRefreshContractData]);
  // TABLE OPTIONS
  const table = useMaterialReactTable({
    columns,
    data,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enablePagination: true,
    initialState: {
      density: 'compact',
      pagination: { pageIndex: 0, pageSize: 100 },
      showGlobalFilter: true,
      columnOrder: [
        'action_01',
        'manufacturer',
        'product_manufacturers',
        'publicaiton_option',
        'sold_price',
        'contract_status',
        'time_created',
        'last_status_changed',
      ],
    },
    enableColumnResizing: true,
    enableRowSelection: false,
    // state: { rowSelection: selectedRowIds },
    // onRowSelectionChange: setSelectedRowIds,
    layoutMode: 'grid',
    muiTablePaperProps: {
      sx: {
        backgroundColor: 'white',
        padding: '18px',
        borderRadius: '18px',
        maxWidth: '1600px',
        mx: 'auto',
      },
    },
    muiTableContainerProps: { sx: { maxHeight: '700px' } },
    muiTableBodyRowProps: { sx: { fontWeight: 'bold', backgroundColor: 'white' } },
    muiTableHeadRowProps: {
      sx: {
        backgroundColor: 'white',
        pt: 2,
        fontWeight: 'bold',
        height: 'auto',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        mb: 0,
      },
    },
    muiTableHeadCellProps: {
      sx: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        borderBottomColor: theme.palette.secondary.main,
        pb: 2,
      },
    },
    muiSearchTextFieldProps: {
      placeholder: 'Search Publications',
      sx: { minWidth: '500px' },
      variant: 'outlined',
    },
    renderTopToolbar: () => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: '15px',
        }}
      >
        <Box sx={{ gridColumn: 'span 3' }}>
          <ContainerTitle title={title} />
        </Box>
        <Box sx={{ gridColumn: 'span 2' }}>
          <MrtGlobalFilterTextField table={table} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {/* <CreatePublicationButton /> */}
          <CreateNewContractButton publicationId={id} />
          <MrtToggleDensePaddingButton table={table} />
          <MrtToggleFiltersButton table={table} />
          <MrtShowHideColumnsButton table={table} />
          <MrtToggleFullScreenButton table={table} />
          {/* <ContactMaterialTableDropDownMenu /> */}
        </Box>
      </Box>
    ),
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  return (
    <Box className='ContractTable' sx={{ px: 6, pt: 6, boxSizing: 'border-box', height: '100%' }}>
      <MaterialReactTable table={table} />
    </Box>
  );
};

export default DashBoardTable;
