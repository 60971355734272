import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Products from './Components/Products';
import Accounts from './Components/Accounts';
import Edi from './Components/Edi';
import Manufacturer from './Components/Manufacturer';
import Printing from './Components/Printing';
import Orders from './Components/Orders';
import Invoices from './Components/Invoices';
import Payments from './Components/Payments';
import Pos from './Components/Pos';
import { Box, Button } from '@mui/material';
import { Divider, Tabs, Tab } from '@mui/material';

const HelpDeskTools = () => {
  const [selectedTopMenu, setSelectedTopMenu] = useState('');
  const userPermission = useSelector((state) => state.userPermission.value);
  console.log('userPermission :', userPermission);

  const handleTopMenuChange = (event, newValue) => {
    setSelectedTopMenu(newValue);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Tabs
          value={selectedTopMenu}
          onChange={handleTopMenuChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='Departments'
        >
          {userPermission[953] && <Tab label='Products' value='Products' />}
          {(userPermission[949] || userPermission[950]) && <Tab label='Edi' value='Edi' />}
          {userPermission[955] && <Tab label='Accounts' value='Accounts' />}
          {userPermission[951] && <Tab label='Manufacturer' value='Manufacturer' />}
          {userPermission[952] && <Tab label='Printing' value='Printing' />}
          {userPermission[956] && <Tab label='Orders' value='Orders' />}
          {userPermission[962] && <Tab label='Payments' value='Payments' />}
        </Tabs>
      </Box>
      <Divider sx={{ marginBottom: 2 }} />
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
        {selectedTopMenu === 'Products' && <Products />}
        {selectedTopMenu === 'Edi' && <Edi />}
        {selectedTopMenu === 'Accounts' && <Accounts />}
        {selectedTopMenu === 'Manufacturer' && <Manufacturer />}
        {selectedTopMenu === 'Printing' && <Printing />}
        {selectedTopMenu === 'Orders' && <Orders />}
        {selectedTopMenu === 'Payments' && <Payments />}
        {selectedTopMenu === 'Invoices' && <Invoices />}
        {selectedTopMenu === 'Pos' && <Pos />}
      </Box>
    </>
  );
};

export default HelpDeskTools;
