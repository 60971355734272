import { gridColumnGroupsHeaderStructureSelector } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getAllPublication,
  getAllPublicationProducts,
  getAllPublicationTypes,
} from '../../../_GlobalFunctions/publicationFunctions';

const PublicationDetails = () => {
  // INIT's
  const { pub_id } = useParams();

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const work = async () => {
      try {
        const [pubs, types, products] = await Promise.all([
          getAllPublication(),
          getAllPublicationTypes(),
          getAllPublicationProducts(),
        ]);

        const pubDetails = pubs.find((e) => e.ss_publication_id === parseInt(pub_id));
        const allDetails = {
          ...pubDetails,
          publication_type: types.find(
            (t) => t.ss_publication_type_id === pubDetails.ss_publication_type_id
          ).description,
          products: [
            ...products
              .filter(
                (e) =>
                  typeof e.types === 'string' &&
                  e.types.split(',').includes(String(pubDetails.ss_publication_type_id))
              )
              .map((p) => ({
                ss_publication_product_id: p.ss_publication_product_id,
                description: p.description,
              })),
          ],
        };
        setData({ ...allDetails });
        console.log('products: ', products);
        setLoading(false);
      } catch (error) {
        return console.log('error: ', error);
      }
    };
    work();
  }, [pub_id]);

  if (loading) {
    return <div>...Loading</div>;
  }

  console.log('data: ', data);
  return <div>PublicationDetails {pub_id}</div>;
};

export default PublicationDetails;
