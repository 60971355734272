import { ProductDataContextState } from '../../../Context/Marketing/ProductData/ProductDataContext';
import { useMemo, useContext, useState, useEffect } from 'react';

import {
  MaterialReactTable,
  useMaterialReactTable,
//   MRT_GlobalFilterTextField,
//   MRT_ToggleFiltersButton,
} from 'material-react-table';

import {
  Breadcrumbs,
  Link,
  Button
} from '@mui/material';

import DashboardDrawer from '../Dashboard/components/DashboardDrawer';

export default function ProductDataGeneralTable( props ) {

    const { products, allCategories, allDepartments, allTypes, allManufacturers, getDesiredSkuInfo } = useContext(ProductDataContextState)
    const urlParse = window.location.href.split('/')
    const [data, setData] = useState([{}])
    const [loading, setLoading] = useState(false)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const _getDesiredSkuInfo = async (type, value) => {
        setLoading(true);
        const result = await getDesiredSkuInfo(type.toLowerCase(), value);
        console.log(result)
        setData(result);
        setLoading(false);
      };

    useEffect(() => {
        _getDesiredSkuInfo(urlParse[5], urlParse[6])
    }, [])

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
    }

  const columns = useMemo(
    () => [
        {
                        id:'Vendor/Brand',
                        accessorKey: 'manufacturer',
                        header:'Vendor/Brand',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allManufacturers
                    },{
                        id:200,
                        accessorKey: 'department',
                        header:'Dept',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allDepartments
                    },{
                        id:300,
                        accessorKey: 'type',
                        header:'Item Type',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allTypes
                    },{
                        id:400,
                        accessorKey: 'category',
                        header:'Category',
                        size:150,
                        filterVariant: 'select',
                        filterSelectOptions: allCategories
                    },{
                        id:500,
                        accessorKey: 'upc_code',
                        header:'UPC Code',
                        size:150,
                    },{
                        id:600,
                        accessorKey: 'product_id',
                        header:'SS#',
                        size:150,
                    },{
                        id:700,
                        accessorKey: 'detailed_description',
                        header:'Description',
                        size:350,
                        grow: false
                    },{
                        id:800,
                        accessorKey: 'model',
                        header:'Model',
                        size:150,
                    },{
                        id:900,
                        accessorKey: 'series',
                        header:'Series',
                        size:150,
                    },{
                        id:1000,
                        accessorKey: 'scan1',
                        header:'Scan 1',
                        size:150,
                    },{
                        id:1100,
                        accessorKey: 'scan2',
                        header:'Scan 2',
                        size:150,
                    }
    ]
  );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: true,
        enableRowActions: true,
        enableStickyHeader: true
        

      });

      return(
        <div>
            <Breadcrumbs separator=">">
                <Link
                    href='/product-data/dashboard'
                    padding={2}
                    underline="hover"
                    color="secondary"
                >
                    Dashboard
                </Link>
                <Link
                    padding={2}
                    underline='hover'
                    color='primary'
                >
                    General Product Table
                </Link>
            </Breadcrumbs>
            <Button onClick={openDrawer} sx={{ flexDirection:'row-reverse'}}>Options</Button>
            <MaterialReactTable table={table}/>
            <DashboardDrawer drawerOpen={drawerOpen} closeDrawer={closeDrawer}/>
        </div>
    )
};