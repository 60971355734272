import { Box, Container, Grid2 } from '@mui/material';
import React from 'react';
import DashboardCardWrapper from './DashboardCardWrapper';
import MediaKitCard from './MediaKitCard';
import ProductPerformanceCard from './ProductPerformanceCard';

const DashboardCards = () => {
  return (
    <Grid2 sx={{ maxWidth: 'xl', mx: 'auto', mt: '24px' }} container spacing={2}>
      <MediaKitCard />
      <ProductPerformanceCard />
      {/* {[...Array(12)].map((_, idx) => (
				<DashboardCardWrapper key={idx}>{idx}</DashboardCardWrapper>
			))} */}
    </Grid2>
  );
};

export default DashboardCards;
