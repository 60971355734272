import React, { useEffect, useState } from 'react';
import { facetOptions } from './components/facetOptions';
import { Box, Button, MenuItem, TextField } from '@mui/material';
//removeAndApplyAttributeValue(replaceValue = 0, addedValue = 0, items = [])
import { removeAndApplyAttributeValue } from '../../../_GlobalFunctions/pdm';
import axios from 'axios';

const OptionUpdate = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [productList, setProductList] = useState([]);
  const [removeValue, setRemoveValue] = useState(0);
  const [addValue, setAddValue] = useState(0);
  const [selectedFacetId, setSelectedFacetId] = useState(0);

  useEffect(() => {
    const fetch = async () => {
      const res = await axios.get(`/products/usedOptions/${selectedFacetId}`);
      setData(res.data);
      setLoading(false);
    };
    fetch();
  }, [selectedFacetId]);

  const handleClick = async () => {
    removeValue !== 0 && setData(data.filter((e) => e.product_facet_option_id !== removeValue));
    setRemoveValue(0);
    const res = await removeAndApplyAttributeValue(removeValue, addValue, productList);
    console.log(`${res} || [${productList}],${removeValue},${addValue}`);
  };

  const handleChange = (e) => {
    const getProductIds = async () => {
      const data = await axios.get(`/products/getIdsFromOption/${e}`);
      setProductList(data.data);
      setRemoveValue(e);
      console.log('items: ', data);
    };
    getProductIds();
  };

  const handleAddChange = (e) => {
    setAddValue(e);
  };

  if (loading) {
    return <div>...loading</div>;
  }

  console.log('res: ', data[0]);
  return (
    <div style={{ margin: '50px' }}>
      <h5>Remove:</h5>
      <TextField
        sx={{ width: '350px' }}
        select
        label='Option To Remove'
        value={removeValue}
        onChange={(e) => handleChange(e.target.value)}
      >
        {[{ product_facet_option_id: 0, facet_value: '-' }, ...data].map((option) => (
          <MenuItem key={option.product_facet_option_id} value={option.product_facet_option_id}>
            {option.facet_value}
          </MenuItem>
        ))}
      </TextField>

      <h5>Add:</h5>
      <TextField
        sx={{ width: '350px' }}
        select
        label='Option To Add'
        value={addValue}
        onChange={(e) => handleAddChange(e.target.value)}
      >
        {[{ product_facet_option_id: 0, facet_value: '-' }, ...data].map((option) => (
          <MenuItem key={option.product_facet_option_id} value={option.product_facet_option_id}>
            {option.facet_value}
          </MenuItem>
        ))}
      </TextField>
      <Box sx={{ mt: 2 }}>
        <Button variant='contained' onClick={() => handleClick()}>
          Replace Value
        </Button>
      </Box>
    </div>
  );
};

export default OptionUpdate;
