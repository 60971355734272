import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  FormGroup,
  IconButton,
  lighten,
  Snackbar,
  styled,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import {
  MaterialReactTable,
  MRT_ToggleDensePaddingButton as MrtToggleDensePaddingButton,
  MRT_ToggleFiltersButton as MrtToggleFiltersButton,
  MRT_ToggleFullScreenButton as MrtToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton as MrtToggleGlobalFilterButton,
} from 'material-react-table';
import { ContentCopy, Help, OpenInNew, Refresh } from '@mui/icons-material';
import InnerTable from './InnerTable';
import ExcelExport from '../_GlobalComponents/ExcelExport';
import MobileList from '../_GlobalComponents/MobileList';
import dayjs from 'dayjs';
import { setIsLoading } from '../../Redux/LoadingSlice';
import { useDispatch, useSelector } from 'react-redux';

const BlueSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: '#00325a',
    '&:hover': {
      backgroundColor: 'rgba(0, 50, 90, 0.08)',
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#00325a',
  },
  '& .MuiSwitch-switchBase': {
    color: '#00325a',
    '&:hover': {
      backgroundColor: 'rgba(0, 50, 90, 0.08)',
    },
  },
  '& .MuiSwitch-track': {
    backgroundColor: '#00325a',
  },
}));

const Exhibitors = () => {
  const userId = useSelector((state) => state.userInformation.value.userID);
  const [data, setData] = useState([]);
  const [isAllowed, setIsAllowed] = useState(null);
  const [groupByAddon, setGroupByAddon] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const dispatch = useDispatch();

  const getExhibitors = useCallback(() => {
    dispatch(setIsLoading(true));
    axios
      .get('/exhibitors/all', {
        params: { groupByAddon: groupByAddon },
      })
      .then((response) => {
        const fixedData = fixData(groupByAddon, response.data);
        setData(fixedData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  }, [groupByAddon, dispatch]);

  const getIsAllowed = useCallback(() => {
    axios
      .post('/exhibitors/allow', { userId: userId })
      .then((response) => {
        setIsAllowed(typeof response.data === 'boolean' ? response.data : false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userId]);

  useEffect(() => {
    getExhibitors();
  }, [getExhibitors]);

  useEffect(() => {
    getIsAllowed();
  }, [getIsAllowed]);

  const fixData = (groupByAddon, data) => {
    if (data.length > 0) {
      const formatPhoneNumber = (phoneNumber) => {
        // Check if it's a 10-digit string
        if (phoneNumber.length === 10 && /^\d+$/.test(phoneNumber)) {
          const areaCode = phoneNumber.slice(0, 3);
          const prefix = phoneNumber.slice(3, 6);
          const lineNumber = phoneNumber.slice(6, 10);
          return `(${areaCode}) ${prefix}-${lineNumber}`;
        } else {
          // Return as is if not a 10-digit string
          return phoneNumber;
        }
      };

      if (groupByAddon) {
        // Handle nested data structure
        data.forEach((addon) => {
          addon.exhibitors.forEach((exhibitor) => {
            exhibitor.exhibitor_phone = formatPhoneNumber(exhibitor.exhibitor_phone);
          });
        });
      } else {
        // Handle flat data structure
        data.forEach((item) => {
          item.exhibitor_phone = formatPhoneNumber(item.exhibitor_phone);
        });
      }

      return data;
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const columns = groupByAddon
    ? [
        {
          header: 'Addon',
          accessorKey: 'addon_description',
          Cell: ({ renderedCellValue, row }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
              <Tooltip
                title={
                  <Typography variant='body1' sx={{ fontSize: 14 }}>
                    {row.original.addon_detail}
                  </Typography>
                }
                placement='right'
              >
                <Help fontSize='small' sx={{ ml: 0.5, cursor: 'pointer' }} />
              </Tooltip>
            </Box>
          ),
        },
        {
          header: 'Type',
          accessorKey: 'addon_type_description',
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: '# Exhibitors',
          id: 'fn_exhibitor_number',
          accessorFn: (row) => row.exhibitors.length,
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Total Qty / Max Qty',
          id: 'fn_quantity_ratio',
          accessorFn: (row) =>
            `${row.exhibitors.reduce((sum, exhibitor) => {
              return sum + Number(exhibitor.addon_qty_selected);
            }, 0)} / ${row.addon_show_limit === 0 ? 'unlimited' : row.addon_show_limit}`,
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Individual Price',
          id: 'fn_formatted_addon_price',
          accessorFn: (row) =>
            Number(row.addon_price).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
          sortingFn: (rowA, rowB) => {
            const valueA = Number(String(rowA.original.addon_price).replace(/[^0-9.-]+/g, ''));
            const valueB = Number(String(rowB.original.addon_price).replace(/[^0-9.-]+/g, ''));

            return valueA - valueB;
          },
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Total Sold',
          id: 'fn_formatted_total_sold',
          accessorFn: (row) =>
            (
              Number(row.addon_price) *
              row.exhibitors.reduce((sum, exhibitor) => {
                return sum + Number(exhibitor.addon_qty_selected);
              }, 0)
            ).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
          sortingFn: (rowA, rowB) => {
            const valueA = Number(
              rowA.getValue('fn_formatted_total_sold').replace(/[^0-9.-]+/g, '')
            );
            const valueB = Number(
              rowB.getValue('fn_formatted_total_sold').replace(/[^0-9.-]+/g, '')
            );

            return valueA - valueB;
          },
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
          Footer: ({ table }) => {
            const grandTotalSold = table
              .getFilteredRowModel()
              .rows.map((row) => row.original)
              .reduce((total, addon) => {
                const addonTotalSold =
                  addon.exhibitors.reduce((sum, exhibitor) => {
                    return sum + Number(exhibitor.addon_qty_selected);
                  }, 0) * Number(addon.addon_price);
                return total + addonTotalSold;
              }, 0);

            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <Typography variant='body1' color='rgba(35, 34, 34, 0.87)' fontWeight={600}>
                  Grand Total Sold:
                </Typography>
                <Typography variant='body1' color='rgba(35, 34, 34, 0.87)'>
                  {grandTotalSold.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              </Box>
            );
          },
        },
      ]
    : [
        {
          header: 'Company Name',
          accessorKey: 'exhibitor_name',
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Booth',
          id: 'fn_booth_info',
          accessorFn: (row) => `${row.booth_number} (${row.booth_type})`,
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: '# Addons',
          id: 'fn_addon_number',
          accessorFn: (row) =>
            row.addons.reduce((sum, addon) => {
              return sum + Number(addon.addon_qty_selected);
            }, 0),
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
        },
        {
          header: 'Company Email',
          accessorKey: 'exhibitor_email',
          Cell: ({ renderedCellValue, row }) => {
            const [isHovered, setIsHovered] = useState(false);

            const handleMouseEnter = () => {
              setIsHovered(true);
            };

            const handleMouseLeave = () => {
              setIsHovered(false);
            };

            const handleCopy = (text) => {
              navigator.clipboard.writeText(text);
              setSnackbarOpen(false);
              setTimeout(() => setSnackbarOpen(true), 100);
            };

            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {String(row.original.exhibitor_email).trim().length > 0 && (
                  <>
                    <a
                      href={`mailto:${row.original.exhibitor_email}`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                    >
                      <Typography variant='body1'>{renderedCellValue}</Typography>
                    </a>
                    <IconButton
                      onClick={() => handleCopy(row.original.exhibitor_email)}
                      sx={{ m: -1, ...(!isHovered && { opacity: 0 }) }}
                    >
                      <ContentCopy
                        sx={{
                          cursor: 'pointer',
                          color: '#2471a3',
                          fontSize: 'large',
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Box>
            );
          },
        },
        {
          header: 'Company Phone #',
          accessorKey: 'exhibitor_phone',
          Cell: ({ renderedCellValue, row }) => {
            const [isHovered, setIsHovered] = useState(false);

            const handleMouseEnter = () => {
              setIsHovered(true);
            };

            const handleMouseLeave = () => {
              setIsHovered(false);
            };

            const handleCopy = (text) => {
              navigator.clipboard.writeText(text);
              setSnackbarOpen(false);
              setTimeout(() => setSnackbarOpen(true), 100);
            };

            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                {String(row.original.exhibitor_phone).trim().length > 0 && (
                  <>
                    <a
                      href={`tel:${row.original.exhibitor_phone}`}
                      style={{
                        textDecoration: 'none',
                        color: 'inherit',
                      }}
                      onMouseEnter={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                      onMouseLeave={(e) => (e.currentTarget.style.textDecoration = 'none')}
                    >
                      <Typography variant='body1'>{renderedCellValue}</Typography>
                    </a>
                    <IconButton
                      onClick={() => handleCopy(row.original.exhibitor_phone)}
                      sx={{ m: -1, ...(!isHovered && { opacity: 0 }) }}
                    >
                      <ContentCopy
                        sx={{
                          cursor: 'pointer',
                          color: '#2471a3',
                          fontSize: 'large',
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Box>
            );
          },
        },
        {
          header: 'Payment Total',
          id: 'fn_formatted_payment_total',
          accessorFn: (row) =>
            Number(row.payment_total).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
          Cell: ({ renderedCellValue }) => (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant='body1'>{renderedCellValue}</Typography>
            </Box>
          ),
          Footer: ({ table }) => {
            const grandTotalSold = table
              .getFilteredRowModel()
              .rows.map((row) => row.original)
              .reduce((total, exhibitor) => {
                return total + Number(exhibitor.payment_total);
              }, 0);

            return (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                }}
              >
                <Typography variant='body1' color='rgba(35, 34, 34, 0.87)' fontWeight={600}>
                  Grand Total:
                </Typography>
                <Typography variant='body1' color='rgba(35, 34, 34, 0.87)'>
                  {grandTotalSold.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </Typography>
              </Box>
            );
          },
        },
      ];

  return (
    <>
      <Box
        sx={{
          p: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MaterialReactTable
          key={groupByAddon ? 'addon-view' : 'exhibitor-view'}
          columns={columns}
          data={data}
          enableRowActions={!groupByAddon && isAllowed === true}
          enableHiding={false}
          enableStickyHeader
          enableStickyFooter
          initialState={{
            showColumnFilters: false,
            density: 'compact',
            pagination: { pageIndex: 0, pageSize: 20 },
          }}
          positionToolbarAlertBanner='bottom'
          renderToolbarInternalActions={({ table }) => {
            return (
              <Box sx={{ whiteSpace: 'pre' }}>
                <MrtToggleGlobalFilterButton table={table} />
                <MrtToggleFiltersButton table={table} />
                <MrtToggleDensePaddingButton table={table} />
                <MrtToggleFullScreenButton table={table} />
                <IconButton
                  tabIndex={0}
                  type='button'
                  aria-label='Refresh'
                  onClick={() => {
                    getExhibitors();
                    getIsAllowed();
                  }}
                >
                  <Refresh />
                </IconButton>
              </Box>
            );
          }}
          renderDetailPanel={({ row }) => (
            <Box
              sx={{
                '& > .MuiPaper-root': {
                  boxShadow: 'none',
                  borderStyle: 'solid',
                  borderColor: lighten(theme.palette.primary.main, 0.5),
                  pt: 1,
                  '& > .MuiBox-root:first-of-type': {
                    '& > .MuiBox-root': {
                      '& > .MuiBox-root': {
                        '& > .MuiBox-root': {
                          '& > .MuiTypography-root': {
                            color: lighten(theme.palette.primary.main, 0.25),
                          },
                        },
                      },
                    },
                  },
                },
              }}
            >
              <InnerTable
                data={groupByAddon ? row.original.exhibitors : row.original.addons}
                isAllowed={isAllowed}
                groupByAddon={groupByAddon}
                setSnackbarOpen={setSnackbarOpen}
              />
            </Box>
          )}
          muiTopToolbarProps={() => {
            return {
              sx: {
                '& .MuiBox-root': {
                  alignItems: 'center',
                },
              },
            };
          }}
          renderTopToolbarCustomActions={() => {
            const newData = groupByAddon
              ? data.map((addon) => {
                  return {
                    'Addon Id': addon.addon_id,
                    Addon: addon.addon_description,
                    'Addon Type': addon.addon_type_description,
                    'Addon Description': addon.addon_detail,
                    '# Exhibitors': addon.exhibitors.length,
                    'Total Qty / Max Qty': `${addon.exhibitors.reduce((sum, exhibitor) => {
                      return sum + Number(exhibitor.addon_qty_selected);
                    }, 0)} / ${
                      addon.addon_show_limit === 0 ? 'unlimited' : addon.addon_show_limit
                    }`,
                    'Addon Price': Number(addon.addon_price).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                    'Total Sold': (
                      Number(addon.addon_price) *
                      addon.exhibitors.reduce((sum, exhibitor) => {
                        return sum + Number(exhibitor.addon_qty_selected);
                      }, 0)
                    ).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                  };
                })
              : data.map((exhibitor) => {
                  return {
                    'Exhibitor Id': exhibitor.rotr_exhibitor_id,
                    'Exhibitor Name': exhibitor.exhibitor_name,
                    'Exhibitor Email': exhibitor.exhibitor_email,
                    'Exhibitor Phone': exhibitor.exhibitor_phone,
                    'Exhibitor Website': exhibitor.exhibitor_website,
                    'Booth Number': exhibitor.booth_number,
                    'Booth Price': Number(exhibitor.booth_price).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                    'Booth Type': exhibitor.booth_type,
                    '# Addons': exhibitor.addons.reduce((sum, addon) => {
                      return sum + Number(addon.addon_qty_selected);
                    }, 0),
                    'Payment Type': exhibitor.payment_type,
                    'Payment Total': Number(exhibitor.payment_total).toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }),
                    'Payment Email': exhibitor.payment_email,
                    'Payment Notes': exhibitor.payment_notes,
                  };
                });

            const componentList = [
              <ExcelExport
                data={newData}
                fileName={`exhibitors-${dayjs().format('YYYY-MM-DD_HH-mm-ss')}`}
              />,
              <FormGroup row sx={{ alignItems: 'center' }}>
                <Typography fontSize='large'>Group by Exhibitor</Typography>
                <BlueSwitch
                  checked={groupByAddon}
                  onChange={(e, checked) => {
                    setGroupByAddon(checked);
                    setData([]);
                  }}
                />
                <Typography fontSize='large'>Group by Addon</Typography>
              </FormGroup>,
            ];

            return (
              <>
                {isMobile ? (
                  <MobileList componentList={componentList} />
                ) : (
                  componentList.map((component, index) => (
                    <React.Fragment key={index}>{component}</React.Fragment>
                  ))
                )}
              </>
            );
          }}
          renderRowActions={({ row }) => (
            <Box>
              <Button
                variant='contained'
                href={`https://www.rendezvousonthered.com/login/${row.original.access_code}?dest=e-register`}
                target='_blank'
                rel='noopener noreferrer'
                startIcon={<OpenInNew />}
              >
                Log In
              </Button>
            </Box>
          )}
          muiTablePaperProps={() => {
            return {
              sx: {
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              },
            };
          }}
          muiTableContainerProps={() => {
            return {
              sx: {
                flexGrow: 1,
              },
            };
          }}
          muiTableBodyRowProps={({ isDetailPanel, row, table, staticRowIndex }) => {
            if (isDetailPanel) {
              const paginationRowModel = table.getPaginationRowModel();
              const isNextRowExpanded =
                staticRowIndex < paginationRowModel.rows.length - 1 &&
                paginationRowModel.rows[staticRowIndex + 1].getIsExpanded();

              return {
                sx: {
                  '& > .MuiTableCell-root': {
                    position: 'relative',
                    transitionProperty: 'border-width, border-color, border-top, padding',
                    transitionDuration: '150ms, 150ms, 0s, 150ms',
                    transitionTimingFunction: 'ease-in-out',
                    borderLeft: 'solid 0px transparent',
                    borderRight: 'solid 0px transparent',
                    borderBottom: isNextRowExpanded
                      ? 'solid 0px transparent !important'
                      : 'solid 0px transparent',
                    ...(row.getIsExpanded() && {
                      border: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
                      borderTop: 'none',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        borderTop: '1px solid rgba(224, 224, 224, 1)',
                      },
                    }),
                  },
                },
              };
            } else {
              return {
                sx: {
                  '& > .MuiTableCell-root': {
                    transitionProperty: 'border-width, border-color, border-bottom',
                    transitionDuration: '150ms, 150ms, 0s',
                    transitionTimingFunction: 'ease-in-out',
                    borderTop: 'solid 0px transparent',
                    borderLeft: 'solid 0px transparent',
                    borderRight: 'solid 0px transparent',
                    ...(row.getIsExpanded() && {
                      borderTop: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
                      borderBottom: 'none',
                    }),
                  },
                  '& > .MuiTableCell-root:first-of-type': {
                    ...(row.getIsExpanded() && {
                      borderLeft: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
                    }),
                  },
                  '& > .MuiTableCell-root:last-of-type': {
                    ...(row.getIsExpanded() && {
                      borderRight: `solid 3px ${lighten(theme.palette.primary.main, 0.2)}`,
                    }),
                  },
                },
              };
            }
          }}
        />
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message='Copied to clipboard'
      />
    </>
  );
};

export default Exhibitors;
