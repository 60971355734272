import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardCardWrapper from './DashboardCardWrapper';
import { Box, Button } from '@mui/material';

const MediaKitCard = () => {
  // INIT's
  const navigate = useNavigate();

  // FUNCTIONS
  const handleClick = () => navigate('/manufacturer/media-kit');

  return (
    <DashboardCardWrapper>
      <Box>2025 Media Kit</Box>
      <Button variant='contained' onClick={() => handleClick()}>
        Check It Out!
      </Button>
    </DashboardCardWrapper>
  );
};

export default MediaKitCard;
