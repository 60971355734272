import React, { useEffect, useState } from 'react';
import { Box, Button, Chip, styled, useTheme } from '@mui/material';
import { getContractsByBrandId, getEntitiesByIds } from '../../../Feeds/ContractsFeed';
import ContractCardEditContainer from './ContractCardEditContainer';
const imageURL =
  'https://plus.unsplash.com/premium_photo-1667239113306-4c875e7706ca?q=80&w=2667&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D';
// Material Styled Components
const Card = styled(Box)(({ theme }) => ({
  position: 'relative',
  background:
    'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(235,231,231,1) 50%, rgba(255,255,255,1) 100%)',
  padding: '24px',
  borderRadius: '24px',
  border: `solid px ${theme.palette.primary.main}`,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: `2px 2px 8px ${theme.palette.primary.main}66`,
  '.header': {
    position: 'relative',
    backgroundImage: `url(${imageURL})`,
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    aspectRatio: '21/9',
    borderRadius: '24px',
    backgroundColor: 'teal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: `2px 2px 8px ${theme.palette.primary.main}66`,
    '&::before': {
      content: '""',
      backgroundColor: `${theme.palette.primary.main}aa`,
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      borderRadius: '24px',
      border: `solid 2px ${theme.palette.primary.main}`,
    },
    '& p': {
      position: 'relative',
      margin: 0,
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.15em',
    },
  },
  '.content-wrapper': {
    padding: '24px 8px',
    flex: '1',
    display: 'flex',
    gap: '16px',
  },
  '.dates': {
    width: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingTop: '8px',
    '.date-box': {
      '& > *:nth-child(1)': {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
      },
      '& > *:nth-child(2)': {
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
      },
    },
  },
  '.content': {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
}));

const ContractCard = ({ item }) => {
  const theme = useTheme();
  console.log('card item: ', item);
  const [brandData, setBrandData] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      const brandIds = item.product_manufacturers.split(',');
      setBrandData(await getEntitiesByIds(brandIds));
    };
    fetch();
  }, [item.product_manufacturers]);

  const handleManageClick = () => {
    console.log(`a contract_card_${item.ss_publication_contract_id}`);
    const clickedCard = document.getElementById(`contract_card_${item.ss_publication_contract_id}`);
    clickedCard.classList.add('active');
  };

  const handleUpdate = async (data) => {
    try {
      // CALLING getContractsByBrandId WILL RETURN ALL ASSOCIATED CONTRACTS
      const response = await getContractsByBrandId(
        data.entity_id,
        data.parent_entity_id,
        data.entity_type_id,
        data.parent_type_id
      );

      setBrandData(response);
      //	handleOpen();
    } catch (error) {
      console.log('error ViewContractsAction ', error);
    }
  };

  return (
    <Card>
      <Box className='header'>&nbsp;</Box>

      <Box className='content-wrapper'>
        <Box className='dates'>
          <Box className='date-box'>
            <Box>Starts:</Box>
            <Box>{item.publication_start_date}</Box>
          </Box>

          <Box className='date-box'>
            <Box>Expires:</Box>
            <Box>{item.publication_expiration_date}</Box>
          </Box>

          <Box className='date-box'>
            <Box>Art Due:</Box>
            <Box>{item.submit_art_date}</Box>
          </Box>
        </Box>

        <Box className='content'>
          <Box sx={{ fontWeight: 'bold', color: theme.palette.primary.main, fontSize: '1.2em' }}>
            {item.publication_title}
          </Box>
          <Box sx={{ color: theme.palette.secondary.main }}>{item.publication_details}</Box>
        </Box>
      </Box>

      <Box
        sx={{
          color: theme.palette.secondary.main,
          fontWeight: 'bold',
          textAlign: 'center',
          mb: '24px',
        }}
      >
        <span style={{ color: theme.palette.primary.light }}>Option: </span>
        {item.product} @ {`$${item.sold_price}`}
      </Box>

      <Box
        sx={{
          mb: '24px',
          padding: '16px 8px',
          borderTop: `solid 2px ${theme.palette.primary.light}`,
          borderBottom: `solid 2px ${theme.palette.primary.light}`,
          display: 'flex',
          gap: '16px',
          flexWrap: 'wrap',
          justifyContent: 'center',
        }}
      >
        {brandData.map((brand) => (
          <Chip
            sx={{ fontWeight: 'bold' }}
            key={brand.entity_id}
            label={brand.business_name}
            color='secondary'
          />
        ))}
      </Box>

      <Box
        sx={{
          fontWeight: 'bold',
          fontSize: '1.1em',
          mb: '24px',
          textAlign: 'center',
          color:
            item.ss_publication_contract_status_id === 1
              ? theme.palette.success.main
              : theme.palette.warning.main,
        }}
      >
        Status: {item.status} (
        <span style={{ color: theme.palette.primary.light }}>{item.user_name}</span>)
      </Box>
      <Box className='actions'>
        <Button
          fullWidth
          variant='contained'
          sx={{ borderRadius: '12px' }}
          onClick={() => handleManageClick()}
        >
          Manage Contract
        </Button>
      </Box>

      {/* EDIT CONTRACT FORM */}
      <ContractCardEditContainer item={item} />
    </Card>
  );
};

export default ContractCard;
