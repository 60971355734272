import { Close } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ContractFormCard from '../../ContractFormCard/ContractFormCard';

const styles = {
  //backgroundColor: 'rgba(255,255,255,.6)',
  background: 'rgb(255,255,255)',
  background:
    'linear-gradient(196deg, rgba(255,255,255,.8) 0%, rgba(86,132,179,.5) 30%, rgba(255,255,255,.8) 48%, rgba(171,194,217,.7) 76%, rgba(255,255,255,.8) 100%)',
  position: 'absolute',
  width: '100%',
  height: '100%',
  bottom: 0,
  left: 0,
  borderRadius: '24px',
  padding: '24px',
  overflow: 'hidden',
  transform: 'scaleY(0)',
  opacity: 0,
  filter: 'blur(50px)',
  backdropFilter: 'blur(8px)',
  transition: '300ms ease-in-out',
  transformOrigin: 'center bottom',
  animation: 'slide-down .2s ease-out forwards',
  '&.active': {
    animation: 'slide-up .2s ease-out forwards',
  },
  '@keyframes slide-up': {
    From: {
      transform: 'scaleY(0)',
      opacity: 0,
      filter: 'blur(50px)',
    },
    To: {
      transform: 'scaleY(1)',
      opacity: 1,
      filter: 'blur(0px)',
    },
  },
  '@keyframes slide-down': {
    From: {
      transform: 'scaleY(1)',
      opacity: 1,
      filter: 'blur(0px)',
    },
    To: {
      transform: 'scaleY(0)',
      opacity: 0,
      filter: 'blur(50px)',
    },
  },
};

const closerStyles = {
  position: 'absolute',
  top: '8px',
  right: '8px',
};

const closerIconStyles = {
  fontWeight: 'bold',
  fontSize: '1.5em',
  color: 'red',
};

const ContractCardEditContainer = ({ item }) => {
  const theme = useTheme();

  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const getPropData = async () => {
      const dataReturned = await item;
      setData(await dataReturned);
      setLoaded(true);
    };
    getPropData();
  }, [item]);

  const handleManageCloserClick = () => {
    console.log(`contract_card_${data.ss_publication_contract_id}`);
    const clickedCard = document.getElementById(`contract_card_${data.ss_publication_contract_id}`);
    clickedCard.classList.remove('active');
  };

  console.log('passed item to ContractCardEditContainer: ', data);

  return (
    <>
      {loaded && (
        <Box
          id={`contract_card_${data.ss_publication_contract_id}`}
          sx={{ ...styles, color: theme.palette.secondary.main }}
        >
          <ContractFormCard initialData={data} />
          <IconButton sx={{ ...closerStyles }} onClick={() => handleManageCloserClick()}>
            <Close sx={{ ...closerIconStyles }} />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default ContractCardEditContainer;
