import { Box, Chip, MenuItem, TextField } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PublicationContextV2State } from '../../../../../../Context/Marketing/PublicationContextV2';
import { MediaKitContextState } from '../../MediaKitContext';

const years = [
  // new Date().getFullYear() - 1,
  new Date().getFullYear(),
  new Date().getFullYear() + 1,
];

const months = [
  {
    id: 0,
    label: 'ALL',
  },
  {
    id: 1,
    label: 'JAN',
  },
  {
    id: 2,
    label: 'FEB',
  },
  {
    id: 3,
    label: 'MAR',
  },
  {
    id: 4,
    label: 'APR',
  },
  {
    id: 5,
    label: 'MAY',
  },
  {
    id: 6,
    label: 'JUN',
  },
  {
    id: 7,
    label: 'JUL',
  },
  {
    id: 8,
    label: 'AUG',
  },
  {
    id: 9,
    label: 'SEP',
  },
  {
    id: 10,
    label: 'OCT',
  },
  {
    id: 11,
    label: 'NOV',
  },
  {
    id: 12,
    label: 'DEC',
  },
];

const SideNav = () => {
  // INIT's
  const [searchParams, setSearchParams] = useSearchParams();

  // CONTEXT STATE
  const { allPublications, allPublicationTypes } = useContext(PublicationContextV2State);
  const { year, setYear, month, setMonth, type, setType } = useContext(MediaKitContextState);

  // LOCAL STATE
  const [loading, setLoading] = useState(true);
  const [yearState, setYearState] = useState(year);
  const [monthState, setMonthState] = useState(month);
  const [typeState, setTypesState] = useState(type);
  const [availableMonths, setAvailableMonths] = useState([]);
  const [availableTypes, setAVailableTypes] = useState([]);

  // FUNCTIONS
  const handleYearClick = (passedYear) => {
    setYear(passedYear);
    setYearState(passedYear);
  };

  const handleMonthClick = (passedMonth) => {
    setMonth(passedMonth);
    setMonthState(passedMonth);
  };

  const handleTypeChange = (passedType) => {
    setType(passedType);
    setTypesState(passedType);
  };

  // USE EFFECTS
  useEffect(() => {
    const processMonths = (selectedType) => {
      const newMonths = [
        ...new Set(
          selectedType === 0
            ? allPublications
                .filter((pubm) => pubm.start_date.split('-')[0] === year.toString())
                .map((e) => parseInt(e.start_date.split('-')[1]))
            : allPublications
                .filter(
                  (pubm) =>
                    pubm.start_date.split('-')[0] === year.toString() &&
                    pubm.ss_publication_type_id === selectedType
                )
                .map((e) => parseInt(e.start_date.split('-')[1]))
        ),
      ];

      setAvailableMonths(newMonths);
      setLoading(false);
    };

    allPublications.length > 0 && processMonths(typeState === 0 ? 0 : typeState);
  }, [year, month, allPublications, searchParams, typeState]);

  if (loading) {
    return <div>...Loading SideBar</div>;
  }

  return (
    <Box>
      <h3>Choose Year:</h3>
      <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', mb: 4 }}>
        {years.map((year) => (
          <Chip
            key={year}
            sx={{ boxShadow: '2px 2px 8px rgba(0,0,0,.2)', fontWeight: 'bold' }}
            label={year === 0 ? 'ALL' : year}
            onClick={() => handleYearClick(year)}
            variant={year === yearState ? 'filled' : 'outlined'}
            color='primary'
          />
        ))}
      </Box>
      <h3>Choose Month:</h3>
      <Box sx={{ display: 'flex', gap: '16px', flexWrap: 'wrap', mb: 4 }}>
        {months.map((m, idx) => (
          <Chip
            disabled={!availableMonths.includes(m.id) && m.id !== 0}
            key={idx}
            sx={{ boxShadow: '2px 2px 8px rgba(0,0,0,.2)', fontWeight: 'bold' }}
            label={m.label}
            variant={m.id === monthState ? 'filled' : 'outlined'}
            onClick={() => handleMonthClick(m.id)}
            // variant={
            // 	m.id === monthState && availableMonths.includes(m.id) && m.id !== 0 ? 'filled' : 'outlined'
            // }
            color='primary'
          />
        ))}
      </Box>
      <h3>Choose Type:</h3>
      <TextField
        fullWidth
        select
        label='Publication Type:'
        value={typeState}
        onChange={(e) => handleTypeChange(e.target.value)}
      >
        {[{ ss_publication_type_id: 0, description: 'All Types' }, ...allPublicationTypes].map(
          (option) => (
            <MenuItem key={option.ss_publication_type_id} value={option.ss_publication_type_id}>
              {option.description}
            </MenuItem>
          )
        )}
      </TextField>
    </Box>
  );
};

export default SideNav;
