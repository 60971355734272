import React, { useContext, useEffect, useState } from "react";
import { ProductDataContextState } from "../../../Context/Marketing/ProductData/ProductDataContext"; 
import { Autocomplete, Breadcrumbs, Button, ButtonGroup, Drawer, Grid2, Link, Paper, Stack, TextField, Typography, styled } from "@mui/material";
import DashboardDrawer from "./components/DashboardDrawer";

export default function ProductDataDashboard(){
    // CONTEXT STATE
    const { userId, products, getDesiredSkuInfo, allDepartments, allCategories, allManufacturers } = useContext(ProductDataContextState)

    // LOCAL STATE
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [data, setData] = useState([]);
    const [rowLength, setRowLength] = useState(0);

    // USE EFFECT

    useEffect(() => {
        const fetch =  () => {
            setItem(products.find(item => item.product_code === '148355'))            
        }
        fetch()
    }, [products])

    // VARIABLES

    const DashboardCard = styled(Paper)(({ theme }) => ({
        backgroundColor:'whitesmoke',
        textAlign:'center',
        margin:1,
        padding:5

    }))
    const drawerOptionMargin = 3
    const maxWidgetWidth = 500

    //PLACEHOLDER VARIABLES

    const BrandVal = allManufacturers

    const CatVal = allCategories;

    const DeptVal = allDepartments

    //TRIGGERS

    const openDrawer = () => {
        setDrawerOpen(true)
    }

    const closeDrawer = () => {
        setDrawerOpen(false)
    }

    return(<div style={{paddingTop:'5px'}}>
        
        <Breadcrumbs separator=">">
            <Link
                padding={2}
                underline="hover"
                color="primary"
            >
                Dashboard
            </Link>
        </Breadcrumbs>
        <div style={{textAlign:"right", paddingRight:20}}>
        <Button onClick={openDrawer} sx={{ flexDirection:'row-reverse'}}>Options</Button>
        </div>
        <Grid2 
            container 
            spacing={2} 
            columns={{xs: 6, md: 9}}
            alignItems={'center'} 
            display={'flex'} 
            justifyContent={'center'}
        >
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        PLACEHOLDER
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <TextField variant="outlined"/>
                        <Button variant="contained">TEST</Button>
                    </Stack>
                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        Quicknav PLACEHOLDER
                    </Typography>
                    <Link>
                        Quicklink
                    </Link>
                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        INFO PLACEHOLDER
                    </Typography>
                    <Stack direction={'column'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <Typography>Info 1</Typography>
                        <Typography>Info 2</Typography>
                    </Stack>

                </DashboardCard>
            </Grid2>
            <Grid2 size={3} border={2} borderRadius={2} margin={1} boxShadow={5}>
                <DashboardCard>
                    <Typography fontWeight={'bold'}>
                        PLACEHOLDER
                    </Typography>
                    <Stack direction={'row'} spacing={3} justifyContent={'center'} alignItems={'center'}>
                        <TextField variant="outlined"/>
                        <Button variant="contained">TEST</Button>
                    </Stack>

                </DashboardCard>
            </Grid2>
        </Grid2>
        <DashboardDrawer drawerOpen={drawerOpen} closeDrawer={closeDrawer}/>
        
    </div>)
}