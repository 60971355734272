import { Box, ListItemButton, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import IconFinder from '../../_GlobalFunctions/IconFinder';

const MenuItem = styled('li')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  textDecoration: 'none',
  color: theme.palette.text.primary,
  transition: 'background-color 0.3s ease',
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));

const Icon = styled(IconFinder)(({ theme }) => ({
  marginRight: theme.spacing(2),
  fontSize: '1.5rem',
  color: theme.palette.primary.main,
}));

const Title = styled(Typography)({
  fontWeight: 'semibold',
  marginLeft: '0.5rem',
});

const DrawerMenu = () => {
  const userMenu = useSelector((state) => state.userPermission.value);
  const navigate = useNavigate();
  const sortedUserMenu = Object.values(userMenu).sort((a, b) => {
    return a.sort_order - b.sort_order;
  });

  return (
    <Box>
      {sortedUserMenu.map((item, index) => {
        if (item.element_type === 'link' && item.visible === 'true') {
          return (
            <MenuItem key={index}>
              <ListItemButton component='a' href={item.full_path} rel='noopener noreferrer'>
                <Icon name={item.menu_icon} />
                <Title variant='body1'>{item.menu_title}</Title>
              </ListItemButton>
            </MenuItem>
          );
        } else if (item.element_type === 'remote-link' && item.visible === 'true') {
          return (
            <MenuItem key={index}>
              <ListItemButton
                component='a'
                href={item.full_path}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Icon name={item.menu_icon} />
                <Title variant='body1'>{item.menu_title}</Title>
              </ListItemButton>
            </MenuItem>
          );
        } else if (item.element_type === 'route' && item.visible === 'true') {
          const path = item.full_path.replace(/^https?:\/\/[^/]+/, '');
          return (
            <MenuItem key={index}>
              <ListItemButton onClick={() => navigate(path)}>
                <Icon name={item.menu_icon} />
                <Title variant='body1'>{item.menu_title}</Title>
              </ListItemButton>
            </MenuItem>
          );
        }
        return null;
      })}
    </Box>
  );
};

export default DrawerMenu;
